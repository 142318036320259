import http from "../http-common";
import authHeader from "./auth-header";

class MapService {
  getMapsList() {
    return http.get("map", { headers: authHeader() });
  }
  getGroupsList() {
    return http.get("map/group", { headers: authHeader() });
  }
  getMap(map_id) {
    return http.get("map/" + map_id, { headers: authHeader() });
  }
  postMap(map) {
    return http.post(
      "map",
      {
        name: map.name,
        default_center_x: map.default_center_x,
        default_center_y: map.default_center_y,
        default_zoom: map.default_zoom,
        default_extent: map.default_extent
      },
      {
        headers: authHeader()
      }
    );
  }
  putMap(map) {
    return http.put(
      "map/" + map.id,
      {
        name: map.name,
        default_center_x: map.default_center_x,
        default_center_y: map.default_center_y,
        default_zoom: map.default_zoom,
        default_extent: map.default_extent
      },
      {
        headers: authHeader()
      }
    );
  }
  deleteMap(id) {
    return http.delete("map/" + id, { headers: authHeader() });
  }
}

export default new MapService();
