import http from "../http-common";
import authHeader from "./auth-header";

class SiteService {
  getSitesByDep(code_dep) {
    return http.get("site/" + code_dep, { headers: authHeader() });
  }
  getSitesUfByDep(code_dep) {
    return http.get("site/uf/" + code_dep, { headers: authHeader() });
  }
  getSitesParcelleByDep(code_dep) {
    return http.get("site/parcelle/" + code_dep, { headers: authHeader() });
  }
}
export default new SiteService();
