import axios from "axios";

let ax = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json"
  }
});
ax.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status == 401) {
      this.$router.push({ name: "contact" });
    }
  }
);

export default ax;
