<template>
  <v-container>
    <v-data-table
      v-if="addOrUpdating == 0"
      :headers="headers"
      :items="srcMaps"
      hide-default-footer
      disable-pagination
      class="elevation-1"
      :loading="loading"
      :loading-text="$t('admin.loading')"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{ $t("admin.mapList") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="addOrUpdating = 1">
            {{ $t("admin.newMap") }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="
            editedMap = item;
            addOrUpdating = 2;
          "
          >mdi-pencil</v-icon
        >
        <v-icon small @click="deleteMap(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <template v-if="addOrUpdating != 0">
      <UpdateMap
        :tab="tab"
        :groupsList="groupsList"
        :layersList="layersList"
        :mapToEdit="editedMap"
        :addOrUpdating="addOrUpdating"
        @closeEditingPage="closeEditingPage"
        @fillArray="fillArray"
        @toUpdateLayer="toUpdateLayer"
      ></UpdateMap>
    </template>
  </v-container>
</template>
<script>
import MapsServices from "../../../services/map";
import LayerGroupServices from "../../../services/layer_group";
import LayersServices from "../../../services/layer";
import UpdateMap from "./UpdateMap";
export default {
  components: {
    UpdateMap
  },
  props: {
    tab: Number
  },
  data() {
    return {
      loading: true,
      addOrUpdating: 0,
      map: null,
      srcMaps: [],
      layersList: [],
      groupsList: [],
      editedMap: {
        name: null,
        default_center_x: null,
        default_center_y: null,
        default_zoom: null,
        default_extent: [null, null, null, null]
      },
      defaultMap: {
        name: null,
        default_center_x: null,
        default_center_y: null,
        default_zoom: null,
        default_extent: [null, null, null, null]
      },

      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id"
        },
        { text: "Nom", value: "name" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    toUpdateLayer(id, layer) {
      this.$emit("toUpdateLayer", id, layer);
    },
    async fillArray() {
      try {
        this.loading = true;
        this.srcMaps = [];
        let maps = await MapsServices.getMapsList();
        this.srcMaps = maps.data;
        this.loading = false;

        let layers = await LayersServices.getLayerList();
        this.layersList = layers.data;

        let layerGroups = await LayerGroupServices.getGroupsList();
        this.groupsList = layerGroups.data;
      } catch (e) {
        this.$toster.error(e);
      }
    },
    deleteMap(item) {
      confirm(
        'Êtes vous sûr de vouloir supprimer la carte "' + item.name + '" ?'
      ) &&
        MapsServices.deleteMap(item.id)
          .then(() => {
            this.fillArray();
            this.$toast.success(this.$t("toaster.mapDeleted"));
          })
          .catch(err => {
            this.$toast.error(err);
            console.log(err);
          });
    },
    setUpdatedMap(newSet) {
      this.editedMap.default_zoom = newSet.zoom;
      this.editedMap.default_center_x = newSet.center_x;
      this.editedMap.default_center_y = newSet.center_y;
      this.editedMap.default_extent = newSet.extent;
      this.registerActive();
    },
    closeEditingPage() {
      this.editedMap = Object.assign({}, this.defaultMap);
      this.addOrUpdating = 0;
    }
  },
  mounted() {
    this.fillArray();
  }
};
</script>
