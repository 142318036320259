import { Fill, Stroke, Style, Circle, Text } from "ol/style";

/************* PLU *************/
export function fStylePLU(feature) {
  let style = new Style({
    fill: new Fill({}),
    stroke: new Stroke({ color: "#27060B" }),
    text: new Text({
      text: feature.get("typezone"),
      font: "12px Calibri,sans-serif",
      textBaseline: "bottom",
      fill: new Fill({
        color: "#27060B"
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 3
      })
    })
  });
  switch (feature.get("typezone")) {
    case "U":
      style.getFill().setColor("rgba(255, 0, 0, 1.0)");
      break;
    case "A":
    case "Ah":
      style.getFill().setColor("rgba(255, 221, 86, 1.0)");
      break;
    case "AU":
    case "AUc":
    case "AUs":
      style.getFill().setColor("rgba(239, 163, 174, 1.0)");
      break;
    case "N":
    case "Nh":
    case "Nd":
      style.getFill().setColor("rgba(51, 171, 42, 1.0)");
      break;
    default:
      console.log(feature.get("typezone"));
  }
  return style;
}

/************* SITES VACANTS *************/
const sHabitationColor = "101, 191, 134";
const sEquipementColor = "201, 126, 203";
const sIndustrieColor = "255, 221, 133";
const sDependanceColor = "210, 131, 120";
const sCommercialeColor = "41, 128, 185";
const sTertiaireColor = "222, 100, 74";
const sTourismeColor = "128, 128, 128";
const sInconnueColor = "255, 151, 91";
const sOpacitySiteFill = "0.4";
export function fStyleSiteParcelle(feature) {
  let oStyle = new Style({
    stroke: new Stroke({
      color: "rgba(110, 110, 110)",
      width: 3
    }),
    fill: new Fill({
      color: "rgba(110, 110, 110, 0.5)"
    })
  });
  if (feature.getProperties().friche != "Oui")
    oStyle.getStroke().setLineDash([4]);
  switch (feature.get("destination")) {
    case "habitation":
      oStyle
        .getFill()
        .setColor("rgba(" + sHabitationColor + ", " + sOpacitySiteFill + ")");
      oStyle.getStroke().setColor("rgba(" + sHabitationColor + ")");
      break;
    case "equipement":
    case "équipement":
      oStyle
        .getFill()
        .setColor("rgba(" + sEquipementColor + ", " + sOpacitySiteFill + ")");
      oStyle.getStroke().setColor("rgba(" + sEquipementColor + ")");
      break;
    case "industrie":
      oStyle
        .getFill()
        .setColor("rgba(" + sIndustrieColor + ", " + sOpacitySiteFill + ")");
      oStyle.getStroke().setColor("rgba(" + sIndustrieColor + ")");
      break;
    case "dependance":
    case "dépendance":
      oStyle
        .getFill()
        .setColor("rgba(" + sDependanceColor + ", " + sOpacitySiteFill + ")");
      oStyle.getStroke().setColor("rgba(" + sDependanceColor + ")");
      break;
    case "commerciale":
      oStyle
        .getFill()
        .setColor("rgba(" + sCommercialeColor + ", " + sOpacitySiteFill + ")");
      oStyle.getStroke().setColor("rgba(" + sCommercialeColor + ")");
      break;
    case "tertiaire":
      oStyle
        .getFill()
        .setColor("rgba(" + sTertiaireColor + ", " + sOpacitySiteFill + ")");
      oStyle.getStroke().setColor("rgba(" + sTertiaireColor + ")");
      break;
    case "tourisme":
      oStyle
        .getFill()
        .setColor("rgba(" + sTourismeColor + ", " + sOpacitySiteFill + ")");
      oStyle.getStroke().setColor("rgba(" + sTourismeColor + ")");
      break;
    case null:
    case "inconnue":
      oStyle
        .getFill()
        .setColor("rgba(" + sInconnueColor + ", " + sOpacitySiteFill + ")");
      oStyle.getStroke().setColor("rgba(" + sInconnueColor + ")");
      break;
    default:
      console.log(
        "Pas de couleur définie pour la destination : " +
          feature.get("destination")
      );
  }
  return oStyle;
}

export function fStyleSite(feature) {
  let oFill, oStroke;
  switch (feature.get("destination")) {
    case "habitation":
      if (feature.getProperties().friche != "Oui") {
        (oFill = new Fill({
          color: "rgba(" + sHabitationColor + ", " + sOpacitySiteFill + ")"
        })),
          (oStroke = new Stroke({
            color: "rgba(" + sHabitationColor + ")",
            width: 4
          }));
      } else {
        oFill = new Fill({ color: "rgba(" + sHabitationColor + ")" });
      }
      break;
    case "equipement":
    case "équipement":
      if (feature.getProperties().friche != "Oui") {
        (oFill = new Fill({
          color: "rgba(" + sEquipementColor + ", " + sOpacitySiteFill + ")"
        })),
          (oStroke = new Stroke({
            color: "rgba(" + sEquipementColor + ")",
            width: 4
          }));
      } else {
        oFill = new Fill({ color: "rgba(" + sEquipementColor + ")" });
      }
      break;
    case "industrie":
      if (feature.getProperties().friche != "Oui") {
        (oFill = new Fill({
          color: "rgba(" + sIndustrieColor + ", " + sOpacitySiteFill + ")"
        })),
          (oStroke = new Stroke({
            color: "rgba(" + sIndustrieColor + ")",
            width: 4
          }));
      } else {
        oFill = new Fill({ color: "rgba(" + sIndustrieColor + ")" });
      }
      break;
    case "dependance":
    case "dépendance":
      if (feature.getProperties().friche != "Oui") {
        (oFill = new Fill({
          color: "rgba(" + sDependanceColor + ", " + sOpacitySiteFill + ")"
        })),
          (oStroke = new Stroke({
            color: "rgba(" + sDependanceColor + ")",
            width: 4
          }));
      } else {
        oFill = new Fill({ color: "rgba(" + sDependanceColor + ")" });
      }
      break;
    case "commerciale":
      if (feature.getProperties().friche != "Oui") {
        (oFill = new Fill({
          color: "rgba(" + sCommercialeColor + ", " + sOpacitySiteFill + ")"
        })),
          (oStroke = new Stroke({
            color: "rgba(" + sCommercialeColor + ")",
            width: 4
          }));
      } else {
        oFill = new Fill({ color: "rgba(" + sCommercialeColor + ")" });
      }
      break;
    case "tertiaire":
      if (feature.getProperties().friche != "Oui") {
        (oFill = new Fill({
          color: "rgba(" + sTertiaireColor + ", " + sOpacitySiteFill + ")"
        })),
          (oStroke = new Stroke({
            color: "rgba(" + sTertiaireColor + ")",
            width: 4
          }));
      } else {
        oFill = new Fill({ color: "rgba(" + sTertiaireColor + ")" });
      }
      break;
    case "tourisme":
      if (feature.getProperties().friche != "Oui") {
        (oFill = new Fill({
          color: "rgba(" + sTourismeColor + ", " + sOpacitySiteFill + ")"
        })),
          (oStroke = new Stroke({
            color: "rgba(" + sTourismeColor + ")",
            width: 4
          }));
      } else {
        oFill = new Fill({ color: "rgba(" + sTourismeColor + ")" });
      }
      break;
    case null:
    case "inconnue":
      if (feature.getProperties().friche != "Oui") {
        (oFill = new Fill({
          color: "rgba(" + sInconnueColor + ", " + sOpacitySiteFill + ")"
        })),
          (oStroke = new Stroke({
            color: "rgba(" + sInconnueColor + ")",
            width: 4
          }));
      } else {
        oFill = new Fill({ color: "rgba(" + sInconnueColor + ")" });
      }
      break;
    default:
      console.log(
        "Pas de couleur définie pour la destination : " +
          feature.get("destination")
      );
  }
  if (feature.getProperties().friche != "Oui") {
    return new Style({
      image: new Circle({
        radius: 8,
        fill: oFill,
        stroke: oStroke
      })
    });
  } else {
    return new Style({
      image: new Circle({
        radius: 8,
        fill: oFill
      })
    });
  }
}
