<template>
  <v-card
    v-if="display"
    min-width="500"
    class="mx-auto card_map_indicator"
    elevation="10"
  >
    <v-btn icon absolute right small @click="closeIndicator()"
      ><v-icon size="15">mdi-close</v-icon></v-btn
    >
    <v-card-text class="ma-2">
      <v-row align="center">
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-row>
                <v-col align="right" class="pa-0 display-2" cols="6">
                  <span v-if="countAdresseSite !== null">{{
                    countAdresseSite
                  }}</span>
                  <v-progress-circular v-else indeterminate />
                </v-col>
                <v-col class="pa-0 display-2" cols="6">
                  <v-icon size="40">mdi-map-marker</v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" class="pa-0">Adresses</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col align="right" class="pt-2 pb-0 display-1" cols="6">
                  <span v-if="countAdresseParcelle !== null">{{
                    countAdresseParcelle
                  }}</span>
                  <v-progress-circular v-else indeterminate />
                </v-col>
                <v-col class="pa-0 display-2" cols="6">
                  <v-icon size="30">mdi-map-marker-radius</v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" class="pa-0">Parcelles</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col align="right" class="pt-2 pb-0 display-1" cols="6">
                  <span v-if="countAdresseUf !== null">{{
                    countAdresseUf
                  }}</span>
                  <v-progress-circular v-else indeterminate />
                </v-col>
                <v-col class="pa-0 display-2" cols="6">
                  <v-icon size="30">mdi-map-marker-distance</v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" class="pa-0">Unités foncières</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style lang="css" scoped>
.card_map_indicator {
  z-index: 10;
  width: max-content;
  height: max-content;
  position: absolute;
  top: 10px;
  left: 35%;
}
</style>
<script>
export default {
  props: {
    display: Boolean,
    countAdresseSite: Number,
    countAdresseParcelle: Number,
    countAdresseUf: Number
  },
  methods: {
    closeIndicator: function() {
      this.$emit("closeIndicator");
    }
  }
};
</script>
