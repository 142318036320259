<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="mx-auto mt-4" max-width="600" outlined v-if="user">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1"
                >{{ fullName(user) }}
              </v-list-item-title>
              <v-list-item-subtitle
                ><v-chip
                  v-for="role in user.Roles"
                  :key="role.id"
                  class="ma-2"
                  color="indigo"
                  small
                  outlined
                  >{{ role.label }}</v-chip
                ></v-list-item-subtitle
              >
              <v-list-item v-if="user.email">
                <v-list-item-icon
                  ><v-icon color="indigo">mdi-email</v-icon></v-list-item-icon
                >
                <v-list-item-content
                  ><v-list-item-title>{{
                    user.email
                  }}</v-list-item-title></v-list-item-content
                >
              </v-list-item>
              <v-list-item v-if="user.phone_number">
                <v-list-item-icon
                  ><v-icon color="indigo">mdi-phone</v-icon></v-list-item-icon
                >
                <v-list-item-content
                  ><v-list-item-title>{{
                    user.phone_number
                  }}</v-list-item-title></v-list-item-content
                >
              </v-list-item>
              <v-list-item-subtitle v-if="user.created_at && user.updated_at">
                Créé le {{ formatDate(user.created_at) }}, dernière modification
                le {{ formatDate(user.updated_at) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey"
              ><v-icon x-large
                >mdi-account-circle-outline</v-icon
              ></v-list-item-avatar
            >
          </v-list-item>

          <v-card-actions>
            <v-btn text @click="dialog_user = true">
              <v-icon>mdi-account-edit</v-icon> Modifier
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto mt-4" max-width="600" outlined v-if="tiers">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Établissement \ structure</div>
              <v-list-item-title class="headline mb-1">{{
                tiers.name
              }}</v-list-item-title>
              <v-list-item-subtitle v-if="tiers.status">{{
                tiers.status
              }}</v-list-item-subtitle>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="indigo"
                    >mdi-map-marker</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title>{{ tiers.adresse }}</v-list-item-title>
                  <v-list-item-title
                    >{{ tiers.adresse_code }}
                    {{ tiers.adresse_ville }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item v-if="tiers.secteur_activite">
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Secteur d'activité</v-list-item-subtitle
                  >
                  <v-list-item-title>{{
                    tiers.secteur_activite
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="tiers.siret">
                <v-list-item-content>
                  <v-list-item-subtitle>Siret</v-list-item-subtitle>
                  <v-list-item-title>{{ tiers.siret }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="tiers.tva">
                <v-list-item-content>
                  <v-list-item-subtitle>TVA</v-list-item-subtitle>
                  <v-list-item-title>{{ tiers.tva }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="tiers.nom_responsable || tiers.email_responsable"
                inset
              ></v-divider>
              <v-list-item>
                <v-list-item-content
                  v-if="tiers.nom_responsable || tiers.email_responsable"
                >
                  <v-list-item-subtitle>Responsable</v-list-item-subtitle>
                  <v-list-item-title>{{
                    tiers.nom_responsable
                  }}</v-list-item-title>
                  <v-list-item-title v-if="tiers.email_responsable"
                    ><v-icon color="indigo">mdi-email</v-icon>
                    {{ tiers.email_responsable }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey"
              ><v-icon x-large
                >mdi-office-building-outline</v-icon
              ></v-list-item-avatar
            >
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_user" persistent max-width="600px">
      <v-card v-if="user">
        <v-card-title>
          <span class="headline">Modification de votre profil</span>
        </v-card-title>
        <v-form v-model="validUser">
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col cols="8" sm="4" md="4">
                  <v-text-field
                    v-model="user.gender"
                    :rules="[rules.required, rules.maxLength(10)]"
                    label="Appellation"
                    hint="M, Mme, Mlle ..."
                    required
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="user.last_name"
                    :rules="[rules.required, rules.maxLength(255)]"
                    label="Nom *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="user.first_name"
                    :rules="[rules.required, rules.maxLength(255)]"
                    label="Prénom *"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-model="user.phone_number"
                    :rules="[rules.tel]"
                    label="Numéro de téléphone"
                    prepend-inner-icon="mdi-phone"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.email"
                    label="Adresse e-mail *"
                    :rules="[rules.required, rules.email]"
                    prepend-inner-icon="mdi-email-outline"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>* informations obligatoires</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog_user = false"
              >Fermer</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="saveMyUser()"
              :disabled="!validUser"
              >Enregistrer</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { maxLength, required, email, tel } from "@/utils/fields_rules";
import { formatDate } from "@/utils/helpers";
import { mapState } from "vuex";
import UserService from "../services/user";

export default {
  name: "Profile",
  data() {
    return {
      dialog_user: false,
      validUser: false,
      user_tiers: { last_name: "", first_name: "" },
      rules: {
        required,
        maxLength,
        email,
        tel
      }
    };
  },
  computed: {
    ...mapState({
      tiers: state => state.tiers,
      user: state => state.user
    })
  },
  methods: {
    formatDate,
    async saveMyUser() {
      try {
        await UserService.updateUser(this.user);
        this.$toast.success("Profile enregistré");
      } catch (e) {
        this.$toast.error(e.message || e);
      }
    },
    fullName(user) {
      return (
        (user.gender ? user.gender + " " : "") +
        user.first_name +
        " " +
        user.last_name
      );
    }
  },
  mounted() {}
};
</script>
