import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const themes = {
  darkMode: true
};

const opts = {
  theme: {
    themes,
    options: {
      customProperties: true
    }
  }
};

export default new Vuetify(opts);
