import http from "../http-common";
import authHeader from "./auth-header";

class MapLayerService {
  postMapLayer(map_layer) {
    return http.post(
      "map_layer",
      {
        map_id: map_layer.map_id,
        layer_id: map_layer.layer_id
      },
      {
        headers: authHeader()
      }
    );
  }
  deleteMapLayer(map_layer) {
    return http.delete(
      "map_layer/" + map_layer.map_id + "/" + map_layer.layer_id,
      {
        headers: authHeader()
      }
    );
  }
}

export default new MapLayerService();
