<template>
  <v-app>
    <v-app-bar app clipped-left style="z-index: 8">
      <v-toolbar-title
        class="app-title"
        @click="
          loggedIn && $router.currentRoute.name != 'map'
            ? $router.push({ name: 'map' })
            : ''
        "
      >
        {{ $t("app.title") }}&nbsp;<span
          v-if="this.selectedDep"
          class="caption"
        >
          {{ this.selectedDep.name_dep }} ({{ this.selectedDep.insee_dep }})
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <a href="https://modaal.fr/" target="_blank">
        <img
          alt="Modaal"
          style="width: 180px; display: block; margin: 0px auto;"
          :src="
            $vuetify.theme.dark ? 'images/modaal_w.png' : 'images/modaal.png'
          "
        />
      </a>
      <v-btn text to="/map" @click="menu = false" v-if="loggedIn">
        carte
      </v-btn>
      <v-btn
        text
        to="/contact"
        @click="menu = false"
        class="mx-2"
        v-if="loggedIn"
      >
        contact
      </v-btn>
      <v-menu
        v-if="loggedIn"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-cog</v-icon></v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.first_name }}
                  {{ user.last_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

          <v-card-actions>
            <v-checkbox
              dense
              class="px-4 "
              v-model="$vuetify.theme.dark"
              @change="toggle_dark_mode"
              label="Mode sombre"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false" to="/profile">{{
              $t("loginCard.profil")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
@import "./sass/main.scss";

.app-title {
  cursor: pointer;
  font-family: "Days One", sans-serif;
  font-size: 2em;
}
</style>

<script>
import { mapState } from "vuex";
import UserService from "./services/user";

export default {
  async beforeCreate() {
    try {
      if (this.$route.name == "map") this.$router.push({ name: "loading" });
      let res = await UserService.validePath();
      this.$store.commit("setUser", res.data);
      this.$store.commit("setTiers", res.data.Tier);
      this.$store.commit("setMap", res.data.Tier.Map);
      this.$store.commit("setSelectedDep", res.data.Tier.Departments[0]);
      this.loggedIn = true;
      if (this.$route.name != "map" || this.$route.name != "contact")
        this.$router.push({ name: "map" });
    } catch (e) {
      if (this.$router.currentRoute.name != "contact")
        this.$router.push("/contact");
      this.loggedIn = false;
    }
  },
  data: () => ({
    loggedIn: false,
    group: null,
    menu: false
  }),
  computed: {
    ...mapState({
      mapId: state => state.mapId,
      selectedDep: state => state.selectedDep,
      tiersId: state => state.tiersId,
      user: state => state.user
    })
  },
  methods: {
    toggle_dark_mode() {
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  }
};
</script>
