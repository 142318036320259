<template>
  <v-card
    v-if="display"
    class="mx-left card_map_legend"
    min-width="400px"
    elevation="10"
  >
    <v-card-title>
      {{ $t("mapLegend.title")
      }}<v-btn icon absolute right small @click="closeLegend()"
        ><v-icon size="15">mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-list dense>
      <v-list-item
        v-if="layer.length == 0 && layerWithoutTheme.length == 0"
        class="mt-20"
      >
        <v-alert type="info" border="left" outlined>{{
          $t("mapLegend.nolayer")
        }}</v-alert>
      </v-list-item>
      <v-list-group
        v-for="(srcLegend, i) in layer"
        :key="i"
        prepend-icon="mdi-menu-open"
        sub-group
      >
        <template v-slot:activator>
          <v-list-item-content v-if="srcLegend.theme">
            <v-list-item-title v-text="srcLegend.theme"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item v-for="(layer, i) in srcLegend.layer" :key="i" link>
          <v-list-item-icon>
            <img v-bind:src="layer.legend_img" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-if="layer.legend_text"
              v-text="layer.legend_text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-divider v-if="layerWithoutTheme.length > 0" inset></v-divider>
      <v-list-item
        v-for="(srcLegendTheme, i) in layerWithoutTheme"
        :key="'A' + i"
      >
        <v-list-item-icon>
          <img v-bind:src="srcLegendTheme.legend_img" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            v-if="srcLegendTheme.legend_text"
            v-text="srcLegendTheme.legend_text"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<style lang="css" scoped>
.card_map_legend {
  z-index: 10;
  width: max-content;
  height: max-content;
  position: absolute;
  left: 57px;
  top: 1px;
}
</style>

<script>
export default {
  props: {
    display: Boolean,
    layer: Array,
    layerWithoutTheme: Array
  },
  methods: {
    closeLegend: function() {
      this.$emit("closeLegend");
    }
  }
};
</script>
