import http from "../http-common";
import authHeader from "./auth-header";

class ImageService {
  getAllImage() {
    return http.get("image/", { headers: authHeader() });
  }
  getImages(com_id) {
    return http.get("image/by_comment_id/" + com_id, { headers: authHeader() });
  }
  deleteImage(img_id) {
    return http.delete("image/" + img_id, { headers: authHeader() });
  }
  postImage(img) {
    return http.post("image", img, { headers: authHeader() });
  }
}
export default new ImageService();
