import http from "../http-common";
import authHeader from "./auth-header";

class LegendService {
  getLegendByLayer(id) {
    return http.get("legend/by_layer_id/" + id, { headers: authHeader() });
  }
  postLegend(legend) {
    return http.post(
      "legend",
      {
        legend_text: legend.legend_text,
        legend_img: legend.legend_img,
        order: legend.order,
        layer_id: legend.layer_id
      },
      {
        headers: authHeader()
      }
    );
  }
  deleteLegend(legend_id) {
    return http.delete("legend/" + legend_id, {
      headers: authHeader()
    });
  }
}
export default new LegendService();
