import * as layer from "ol/layer";
import * as source from "ol/source";
import { Fill, Stroke, Style, Circle } from "ol/style";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
proj4.defs(
  "EPSG:2154",
  "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

const oStyleMeasure = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)"
  }),
  stroke: new Stroke({
    color: "#ffcc33",
    width: 2
  }),
  image: new Circle({
    radius: 7,
    fill: new Fill({
      color: "#ffcc33"
    })
  })
});
export const oStyleMeasureDraw = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)"
  }),
  stroke: new Stroke({
    color: "rgba(0, 0, 0, 0.5)",
    lineDash: [10, 10],
    width: 2
  }),
  image: new Circle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)"
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)"
    })
  })
});

export const oSourceMeasure = new source.Vector();
export const oLayerMeasure = new layer.Vector({
  name: "Measure tools - hidden",
  source: oSourceMeasure,
  style: oStyleMeasure
});
