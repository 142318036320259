import { i18n } from "@/plugins/i18n";
import { parseDate } from "./helpers";

const t = i18n.t.bind(i18n);

/* GENERAL */
export const required = v => !!v || t("fields.rules.required");

/* STRINGS */
export const maxLength = x => v =>
  !v || v.length <= x || t("fields.rules.max_string", { c: x });

export const minLength = x => v =>
  !v || v.length >= x || t("fields.rules.min_string", { c: x });

export const notEmpty = v => v.length > 0 || t("fields.rules.required");

export const email = v =>
  !v ||
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    v
  ) ||
  t("fields.rules.wrongFormat");

export const date = v =>
  (/^\d{2}\/\d{2}\/\d{4}$/.test(v) && !isNaN(new Date(parseDate(v)))) ||
  t("fields.rules.invalidDate");

/* NUMBERS */
export const number = v => !isNaN(v) || t("fields.rules.invalidNumber");

export const positive = v => v >= 0 || t("fields.rules.positif");

export const int = v => !v || /^-?\d+$/.test(v) || t("fields.rules.integer");

export const max = n => v =>
  !v || v <= n || t("fields.rules.max_number", { n });

export const min = n => v =>
  !v || v >= n || t("fields.rules.min_number", { n });

export const tel = v => !v || /^(\+[0-9])?[0-9]{10}$/.test(v);
