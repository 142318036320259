import http from "../http-common";
import authHeader from "./auth-header";

class CommentsService {
  getAllComments() {
    return http.get("comments/", { headers: authHeader() });
  }
  getCommentsBySiteId(site_id) {
    return http.get("comments/by_site_id/" + site_id, {
      headers: authHeader()
    });
  }
  getCommentsByGisementId(gisement_id) {
    return http.get("comments/by_gisement_id/" + gisement_id, {
      headers: authHeader()
    });
  }
  postComment(Com) {
    return http.post(
      "comments",
      {
        user_id: Com.user_id,
        notice: Com.notice,
        visit: Com.visit,
        type: Com.type,
        site_id: Com.site_id,
        gis_id: Com.gis_id,
        comment: Com.comment,
        created_at: new Date()
      },
      {
        headers: authHeader()
      }
    );
  }
}
export default new CommentsService();
