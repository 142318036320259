<template>
  <v-card tile style="position:absolute;z-index:5">
    <v-navigation-drawer floating permanent mini-variant>
      <!--<v-divider></v-divider>-->
      <v-list nav dense color="secondary">
        <v-list-item link @click="openIndicator">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on" class="ml-0">
                <v-icon color="yellow lighten-2">mdi-chart-line</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ $t("menuMap.tooltip_indicateur") }}</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item link @click="openLegend">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on" class="ml-0">
                <v-icon color="yellow lighten-2"
                  >mdi-format-list-numbered</v-icon
                >
              </v-list-item-icon>
            </template>
            <span>{{ $t("menuMap.tooltip_legende") }}</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item link @click="openLayersControl">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on" class="ml-0">
                <v-icon color="yellow lighten-2">mdi-format-list-checks</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ $t("menuMap.tooltip_couche") }}</span>
          </v-tooltip>
        </v-list-item>
        <!--<v-list-item link>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on" class="ml-0">
                <v-badge content="2" value="2" color="primary" overlap bottom>
                  <v-icon color="yellow lighten-2">mdi-filter-outline</v-icon>
                </v-badge>
              </v-list-item-icon>
            </template>
            <span>{{ $t("menuMap.tooltip_filtre") }}</span>
          </v-tooltip>
        </v-list-item>-->
        <v-list-item link @click="openTool">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on" class="ml-0">
                <v-icon color="yellow lighten-2">mdi-hammer-wrench</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ $t("menuMap.tooltip_outil") }}</span>
          </v-tooltip>
        </v-list-item>
        <!--<v-list-item link @click="openPrintParam">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on" class="ml-0">
                <v-icon color="yellow lighten-2">mdi-printer</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ $t("menuMap.tooltip_impression") }}</span>
          </v-tooltip>
        </v-list-item>-->
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
export default {
  methods: {
    openIndicator: function() {
      this.$emit("openIndicator");
    },
    openLegend: function() {
      this.$emit("openLegend");
    },
    openLayersControl: function() {
      this.$emit("openLayersControl");
    },
    openTool: function() {
      this.$emit("openTool");
    },
    openPrintParam: function() {
      this.$emit("openPrintParam");
    }
  }
};
</script>
