<template>
  <v-card
    class="mx-auto"
    min-width="250"
    max-width="500"
    max-height="450"
    v-if="display"
  >
    <v-card-title class="pa-5">
      {{ featurePopupListTitle }}
      <v-btn icon absolute right small @click="closePopupList()"
        ><v-icon size="15">mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text class="pa-0">
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(layer, i) in featuresPopupList" :key="i">
          <v-expansion-panel-header
            >{{ layer.layer
            }}<v-badge
              inline
              color="secondary darken-2"
              :content="layer.nb_feature"
              style="justify-content: left;"
            ></v-badge
          ></v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-list dense class="pa-0">
              <v-list-item v-for="feature in layer.features" :key="feature.key">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ feature.title }}
                    <v-btn icon @click="getPopup(layer.key_layer, feature.key)">
                      <v-icon small>mdi-eye-outline</v-icon>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>
<style lang="css" scoped></style>
<script>
export default {
  props: {
    display: Boolean,
    featurePopupListTitle: String,
    featuresPopupList: { type: Array, required: true }
  },
  methods: {
    closePopupList: function() {
      this.$emit("closePopupList");
    },
    getPopup: function(key_layer, key) {
      this.$emit("getPopup", key_layer, key);
    }
  }
};
</script>
