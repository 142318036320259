import {
  oLayerSite,
  oLayerSiteUf,
  oLayerSiteParcelle,
  oLayerBasol,
  oLayerBasias,
  oLayerGPUZonage,
  oLayerMajicPMO,
  oLayerDVFplus,
  oLayerDepartement,
  oLayerEpci,
  oLayerCommune,
  oLayerParcellaire
} from "./layer";

export function getLayerList(map_id, code_dep) {
  let layersList = [];
  switch (Number(map_id)) {
    case 1:
      // SIRIN
      if (code_dep >= 2) {
        oLayerCommune.getSource().updateParams({
          CQL_FILTER: "code_insee_du_departement='" + code_dep + "'"
        });
        oLayerDepartement
          .getSource()
          .updateParams({ CQL_FILTER: "code_insee='" + code_dep + "'" });
      }
      layersList = [
        oLayerSite,
        oLayerSiteParcelle,
        oLayerSiteUf,
        oLayerBasol,
        oLayerBasias,
        oLayerGPUZonage,
        oLayerDVFplus,
        oLayerMajicPMO,
        oLayerEpci,
        oLayerCommune,
        oLayerDepartement,
        oLayerParcellaire
      ];
      break;
    default:
      layersList = [
        oLayerBasol,
        oLayerBasias,
        oLayerEpci,
        oLayerCommune,
        oLayerParcellaire
      ];
      console.error(
        "les couches de la carte numéro " +
          map_id +
          " ne sont pas définis (config/map_layers.js)"
      );
  }
  return layersList;
}
