<template>
  <v-container>
    <v-data-table
      v-if="addOrUpdating == 0"
      :headers="headersGroup"
      :items="srcLayerGroups"
      hide-default-footer
      disable-pagination
      :loading="loading"
      :loading-text="$t('admin.loading')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{ $t("admin.groupLayerList") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="addOrUpdating = 1">
            {{ $t("admin.newGroupLayer") }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.theme_id`]="{ item }">
        {{ getThemeName(item.id) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="setLayerGroupToEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteLayerGroup(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <template v-if="addOrUpdating != 0">
      <UpdateLayerGroup
        :layerGroupToEdit="layerGroupToEdit"
        :addOrUpdating="addOrUpdating"
        @closeEditingPage="closeEditingPage"
        @fillArray="fillArray"
      ></UpdateLayerGroup>
    </template>
  </v-container>
</template>
<script>
import UpdateLayerGroup from "./UpdateLayerGroup";
import LayerGroupsServices from "../../../services/layer_group";
import ThemeServices from "../../../services/theme";

export default {
  components: {
    UpdateLayerGroup
  },
  data() {
    return {
      themeList: [],
      addOrUpdating: 0,
      loading: true,
      srcLayerGroups: [],
      layerGroupToEdit: {
        name: null,
        key_layer: null,
        max_zoom: null,
        min_zoom: null,
        opacity: null,
        visible: null,
        theme_id: null,
        searchable: null
      },
      defaultLayerGroup: {
        name: null,
        key_layer: null,
        max_zoom: null,
        min_zoom: null,
        opacity: null,
        visible: null,
        theme_id: null,
        attributes: null
      },
      headersGroup: [
        {
          text: "Nom",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Clé de la couche", value: "key_layer" },
        { text: "Thème", value: "theme_id" },
        { text: "Interrogable", value: "searchable" },
        { text: "Visible", value: "visible" },
        { text: "Zoom minimum", value: "min_zoom" },
        { text: "Zoom maximum", value: "max_zoom" },
        { text: "Opacité", value: "opacity" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    async fillArray() {
      try {
        let layerGroups = await LayerGroupsServices.getGroupsList();
        this.srcLayerGroups = layerGroups.data;
        this.loading = false;
        let themes = await ThemeServices.getThemeList();
        this.themeList = themes.data;
      } catch (e) {
        this.$toast.error(this.$t("toaster.error") + e);
      }
    },
    setLayerGroupToEdit(item) {
      this.addOrUpdating = 2;
      this.layerGroupToEdit = JSON.parse(JSON.stringify(item));
    },
    getThemeName(id) {
      let theme = this.themeList.filter(e => e.id == id)[0];
      if (theme) return theme.name;
    },
    closeEditingPage() {
      this.layerGroupToEdit = Object.assign({}, this.defaultLayerGroup);
      this.addOrUpdating = 0;
    },
    async deleteLayerGroup(item) {
      confirm(
        'Êtes vous sûr de vouloir supprimer le groupe de couche "' +
          item.name +
          '" ?'
      ) &&
        LayerGroupsServices.deleteLayerGroup(item.id)
          .then(() => {
            this.fillArray();
            this.$toast.success("Couche supprimé");
          })
          .catch(err => {
            this.$toast.error(err);
            console.log(err);
          });
    }
  },
  async created() {
    this.fillArray();
  }
};
</script>
