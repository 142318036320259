import http from "../http-common";
import authHeader from "./auth-header";

class ThemeService {
  getThemeList() {
    return http.get("theme", { headers: authHeader() });
  }
  postTheme(theme) {
    return http.post(
      "theme",
      {
        name: theme.name
      },
      {
        headers: authHeader()
      }
    );
  }
  deleteTheme(theme_id) {
    return http.delete("theme/" + theme_id, {
      headers: authHeader()
    });
  }
}

export default new ThemeService();
