<template>
  <div class="GoogleStreeView" />
</template>

<script>
import gmapsInit from "../../config/gmaps";

export default {
  name: "App",
  props: {
    coordinate: Array,
    active: Boolean
  },
  async mounted() {
    try {
      const google = await gmapsInit();
      new google.maps.StreetViewPanorama(this.$el, {
        position: { lat: this.coordinate[1], lng: this.coordinate[0] },
        pov: { heading: 34, pitch: 10 }
      });
    } catch (err) {
      {
        this.$toast.error(err);
        console.log(err);
      }
    }
  }
};
</script>
<style>
.GoogleStreeView {
  width: 500px;
  height: 280px;
  margin-left: auto;
  margin-right: auto;
}
</style>
