<template>
  <v-container fluid fill-height>
    <div style="width: 100%;">
      <v-row>
        <v-col cols="12" align="center" justify="center" class="mb-2">
          <h1>
            {{
              user
                ? "Un problème, une question, une suggestion ?"
                : "Vous êtes intéréssés par notre algorithme de détection de site ?"
            }}
          </h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-card width="400px">
          <v-card-title class="justify-center">
            <h2 style="word-break: normal;">
              Contactez-nous
            </h2>
          </v-card-title>
          <v-card-text>
            <v-form ref="contactForm" v-model="validForm">
              <v-text-field
                v-if="!user"
                label="Nom"
                :disabled="loading"
                v-model="contact.name"
                :rules="[rules.required]"
                validate-on-blur
                v-on:keyup="onKeyup"
              ></v-text-field>
              <v-text-field
                v-if="!user"
                :disabled="loading"
                label="Adresse Mail"
                v-model="contact.email"
                :rules="[rules.required, rules.email]"
                validate-on-blur
                v-on:keyup="onKeyup"
              ></v-text-field>
              <v-text-field
                v-if="!user"
                :disabled="loading"
                label="Entreprise"
                v-model="contact.entreprise"
                validate-on-blur
                v-on:keyup="onKeyup"
              ></v-text-field>
              <v-textarea
                outlined
                :disabled="loading"
                :rules="[rules.required]"
                label="Message"
                validate-on-blur
                @blur="() => $refs.contactForm.resetValidation()"
                :auto-grow="user != null"
                v-model="contact.message"
                v-on:keyup="onKeyup"
              ></v-textarea>
              <v-row justify="center" class="my-2">
                <v-btn
                  color="primary"
                  @click="handleContact"
                  :disabled="!validForm || loading"
                  style="min-width: 180px"
                >
                  <v-progress-circular
                    v-if="loading"
                    size="25"
                    indeterminate
                  ></v-progress-circular>
                  <span v-else>Prendre contact</span>
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import UserService from "../services/user";
import { maxLength, required, email, tel } from "@/utils/fields_rules";

export default {
  name: "Profile",
  data() {
    return {
      loading: false,
      validForm: null,
      contact: {
        name: null,
        email: null,
        entreprise: null,
        message: null
      },
      rules: {
        required,
        maxLength,
        email,
        tel
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    async handleContact() {
      this.loading = true;
      this.$refs.contactForm.validate();
      if (this.validForm) {
        try {
          if (this.user) {
            this.contact.name =
              this.user.gender +
              " " +
              this.user.first_name +
              " " +
              this.user.last_name;
            this.contact.email = this.user.email;
            this.contact.entreprise = this.user.Tier.name;
          }
          await UserService.contact(this.contact);
          this.contact.message = null;
          this.$refs.contactForm.reset();
          this.$toast.success("Demande de contact envoyé!");
        } catch (e) {
          this.$toast.error(e);
        } finally {
          this.loading = false;
        }
      }
    },
    onKeyup: function() {
      if (!this.validForm && this.contact.message.length >= 10)
        this.$refs.contactForm.validate();
    }
  }
};
</script>
