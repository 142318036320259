import Vue from "vue";
import VueRouter from "vue-router";
import Profile from "../views/Profile.vue";
import Contact from "../views/Contact.vue";
import Map from "../views/Map";
import MapAdmin from "../views/MapAdmin";
import Loading from "../views/Loading";

Vue.use(VueRouter);

const routes = [
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/map",
    name: "map",
    component: Map
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact
  },
  {
    path: "/mapAdmin",
    name: "MapAdmin",
    component: MapAdmin
  },
  {
    path: "/loading",
    name: "loading",
    component: Loading
  },
  { path: "*", component: Loading }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from) && to.name != from.name) {
    router.push({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;
