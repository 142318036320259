import { render, staticRenderFns } from "./MapPopupList.vue?vue&type=template&id=74835671&scoped=true&"
import script from "./MapPopupList.vue?vue&type=script&lang=js&"
export * from "./MapPopupList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74835671",
  null
  
)

export default component.exports