import http from "../http-common";
import authHeader from "./auth-header";

class LayerGroupService {
  getGroupsList() {
    return http.get("layer_group", { headers: authHeader() });
  }
  postMapLayerGroup(layer_group) {
    return http.post(
      "/layer_group/map",
      {
        map_id: layer_group.map_id,
        layer_group_id: layer_group.layer_group_id
      },
      {
        headers: authHeader()
      }
    );
  }
  deleteMapLayerGroup(layer_group) {
    return http.delete(
      "layer_group/map/" +
        layer_group.map_id +
        "/" +
        layer_group.layer_group_id,
      {
        headers: authHeader()
      }
    );
  }
  postLayerGroup(layer_group) {
    return http.post(
      "layer_group",
      {
        name: layer_group.name,
        key_layer: layer_group.key_layer,
        max_zoom: layer_group.max_zoom,
        attributes: layer_group.attributes,
        min_zoom: layer_group.min_zoom,
        visible: layer_group.visible,
        opacity: layer_group.opacity,
        theme_id: layer_group.theme_id,
        searchable: layer_group.searchable
      },
      {
        headers: authHeader()
      }
    );
  }
  putLayerGroup(layer_group) {
    return http.put(
      "layer_group/" + layer_group.id,
      {
        name: layer_group.name,
        key_layer: layer_group.key_layer,
        max_zoom: layer_group.max_zoom,
        min_zoom: layer_group.min_zoom,
        attributes: layer_group.attributes,
        visible: layer_group.visible,
        opacity: layer_group.opacity,
        theme_id: layer_group.theme_id,
        searchable: layer_group.searchable
      },
      {
        headers: authHeader()
      }
    );
  }
  deleteLayerGroup(id) {
    return http.delete("layer_group/" + id, { headers: authHeader() });
  }
}

export default new LayerGroupService();
