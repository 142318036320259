<template>
  <v-card>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="cancelEditing()">
        {{ $t("buttons.close") }}
      </v-btn>
    </v-card-actions>
    <v-card-title class="justify-center">
      <span class="headline">{{ formTitleLayer }}</span>
    </v-card-title>
    <v-form v-model="validForm" ref="layer_form">
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-text-field
                  v-model="editedLayer.name"
                  :counter="50"
                  :rules="[rules.required, rules.max(50)]"
                  :label="$t('fields.name') + ' *'"
                  validate-on-blur
                  @keyup.enter="$refs.edit_title.focus()"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedLayer.key_layer"
                  :counter="150"
                  hint="Identifiant de la couche sans espace"
                  :rules="[rules.required, rules.max(150)]"
                  :label="$t('fields.key_layer') + ' *'"
                  validate-on-blur
                  ref="edit_key_layer"
                  @keyup.enter="$refs.edit_z_min.focus()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <span>Zoom:</span>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-text-field
                  v-model.number="editedLayer.min_zoom"
                  :label="$t('fields.min')"
                  :rules="[rules.number]"
                  type="number"
                  min="0"
                  max="20"
                  validate-on-blur
                  ref="edit_z_min"
                  @keyup.enter="$refs.edit_z_max.focus()"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="editedLayer.max_zoom"
                  :label="$t('fields.max')"
                  type="number"
                  :rules="[rules.number]"
                  min="0"
                  max="20"
                  validate-on-blur
                  ref="edit_z_max"
                  @keyup.enter="$refs.edit_url.focus()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="8">
                <v-text-field
                  v-model="editedLayer.url"
                  :counter="250"
                  :rules="[rules.max(250)]"
                  :label="$t('fields.url')"
                  ref="edit_url"
                  validate-on-blur
                  @keyup.enter="$refs.edit_geo_layer.focus()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-text-field
                  v-model="editedLayer.geoserver_layer"
                  :counter="150"
                  :rules="[rules.max(150)]"
                  :label="$t('fields.geo_layer')"
                  ref="edit_geo_layer"
                  validate-on-blur
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-subheader class="pl-0">
                  {{ $t("fields.opacity") }}
                  <v-row justify="end">
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedLayer.opacity"
                        disabled
                        solo
                        class="mt-0 pt-0"
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-subheader>
                <v-slider
                  v-model="editedLayer.opacity"
                  :thumb-size="24"
                  validate-on-blur
                  :step="0.1"
                  min="0.1"
                  max="1"
                >
                </v-slider>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="cardAddTheme"> </v-row>
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-select
                  v-model="editedLayer.theme_id"
                  :items="srcTheme"
                  item-value="id"
                  item-text="name"
                  :label="$t('admin.selectThemeLayer')"
                  :no-data-text="$t('admin.noDataTheme')"
                  @keyup.enter="$refs.edit_url.focus()"
                  ref="edit_t"
                >
                </v-select>
              </v-col>
              <v-col cols="1">
                <v-dialog v-model="cardAddTheme" width="450" persistent>
                  <template v-slot:activator="{ on: dialog, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          fab
                          x-small
                          color="primary"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...dialog }"
                        >
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <span> {{ $t("admin.tooltip_Theme") }}</span>
                    </v-tooltip>
                  </template>
                  <v-card
                    elevation="2"
                    max-width="344"
                    outlined
                    min-width="450"
                  >
                    <v-card-title>
                      {{ $t("admin.newTheme") }}
                      <v-spacer />
                      <v-btn
                        small
                        color="blue darken-1"
                        text
                        @click="cardAddTheme = !cardAddTheme"
                      >
                        {{ $t("buttons.close") }}
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="pb-0">
                      <v-data-table
                        dense
                        :items="srcTheme"
                        :headers="headersTheme"
                        :items-per-page="5"
                        :footer-props="{
                          itemsPerPageOptions: [5],
                          showFirstLastPage: true
                        }"
                      >
                        <template v-slot:top>
                          <v-toolbar flat dense color="white">
                            <span>{{ $t("admin.listTheme") }}</span>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small @click="deleteTheme(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                      <v-form v-model="validFormTheme" ref="theme_form">
                        <v-text-field
                          v-model="newThemeName"
                          :counter="255"
                          :rules="[rules.required, rules.max(255)]"
                          label="Nom du thème"
                          validate-on-blur
                        />
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="primary" dark small @click="addTheme()">
                        {{ $t("buttons.add") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" v-if="cardAddLegend">
            </v-row>

            <v-row justify="center">
              <v-col cols="3">
                <v-checkbox
                  v-model="editedLayer.visible"
                  :label="$t('fields.visible')"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="editedLayer.searchable"
                  :label="$t('fields.searchable')"
                  persistent-hint
                  :hint="
                    editedLayer.searchable
                      ? ''
                      : 'Supprimera le champ utilisé pour le titre, celui pour la clé et les attributs'
                  "
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="editedLayer.searchable">
              <v-col cols="4">
                <v-text-field
                  v-model="editedLayer.title"
                  :counter="50"
                  :rules="[rules.max(50)]"
                  :label="$t('fields.title')"
                  validate-on-blur
                  ref="edit_title"
                  @keyup.enter="$refs.edit_key_layer.focus()"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedLayer.key"
                  :counter="50"
                  :rules="[rules.max(50)]"
                  :label="$t('fields.key')"
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="editedLayer.searchable">
              <v-col cols="8">
                <v-simple-table dense>
                  <template v-slot:top>
                    <v-toolbar flat color="white">
                      <span>Attributs:</span>
                    </v-toolbar>
                  </template>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Nom
                        </th>
                        <th class="text-left">
                          Libellé
                        </th>
                        <th class="text-left">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in Object.keys(editedLayer.attributes)"
                        :key="item"
                      >
                        <td>
                          <v-text-field
                            @change="setNewAttributeKey(item)"
                            :value="item"
                            @input="attributeKey = $event"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model="editedLayer.attributes[item].label"
                            :value="editedLayer.attributes[item].label"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-icon right small @click="deleteAttribute(item)">
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-text-field
                            :label="$t('admin.newNameAttribute')"
                            v-model="attributeName"
                            @change="setNewAttribute()"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :label="$t('admin.newLabelAttribute')"
                            v-model="attributeLabel"
                            @change="setNewAttribute()"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <small>* {{ $t("fields.required") }}</small>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="saveLayer"
        :disabled="!validForm"
      >
        {{ $t("buttons.save") }}
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-row align="center" justify="center">
      <v-col cols="8">
        <v-data-table
          :headers="headersLegend"
          :items="editedLayer.legend"
          :items-per-page="5"
          :footer-props="{
            itemsPerPageOptions: [5],
            showFirstLastPage: true
          }"
          no-data-text="Aucune légende associée"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <span>{{ $t("admin.listLegend") }}</span>
              <v-spacer></v-spacer>
              <v-dialog v-model="cardAddLegend" width="500" persistent>
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        :disabled="!editedLayer.id"
                        fab
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="{ ...dialog, ...tooltip }"
                      >
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("admin.tooltip_addLegend") }}</span>
                  </v-tooltip>
                </template>
                <v-card elevation="2" outlined>
                  <v-card-title>
                    {{ $t("admin.createLegend") }}
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="cardAddLegend = !cardAddLegend"
                    >
                      {{ $t("buttons.close") }}
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-form v-model="validFormLegend" ref="legend_form">
                      <v-text-field
                        dense
                        v-model="newLegend.legend_text"
                        :counter="255"
                        :rules="[rules.required, rules.max(255)]"
                        label="Texte de la légende"
                        validate-on-blur
                      />
                      <v-text-field
                        dense
                        v-model="newLegend.legend_img"
                        :counter="255"
                        :rules="[rules.max(255)]"
                        label="Chemin de l'image"
                        validate-on-blur
                      />

                      <v-text-field
                        dense
                        v-model="newLegend.order"
                        type="Number"
                        min="1"
                        label="Ordre"
                        validate-on-blur
                      />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" dark small @click="addLegend()">
                      {{ $t("buttons.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteLegend(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import LayersServices from "../../../services/layer";
import LegendServices from "../../../services/legend";
import ThemeServices from "../../../services/theme";

export default {
  data() {
    return {
      srcTheme: [],
      validFormTheme: null,
      newThemeName: null,
      cardAddTheme: false,
      cardAddLegend: false,
      attributeKey: null,
      attributeName: null,
      attributeLabel: null,
      layerCreated: false,
      newLegend: {
        legend_img: null,
        legend_text: null,
        layer_id: null,
        order: 1
      },
      defaultLegend: {
        legend_img: null,
        legend_txt: null,
        layer_id: null,
        order: 1
      },
      editedLayer: {
        name: null,
        key_layer: null,
        max_zoom: null,
        min_zoom: null,
        opacity: 1,
        visible: null,
        theme_id: null,
        url: null,
        geoserver_layer: null,
        searchable: false,
        key: null,
        titre: null,
        attributes: {},
        legend: []
      },
      headersTheme: [
        {
          text: "Nom",
          align: "start",
          value: "name"
        },
        { text: "Actions", value: "actions", sortable: false }
      ],
      headersLegend: [
        {
          text: "Texte de la légende",
          align: "start",
          value: "legend_text"
        },
        { text: "Chemin de l'image", value: "legend_img" },
        { text: "Ordre d'importance", value: "order" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      validForm: false,
      validFormLegend: false,
      rules: {
        required: v => !!v || this.$t("fields.rules.required"),
        number: v =>
          Number.isInteger(v) || !v || this.$t("fields.rules.number"),
        max: x => v =>
          !v || (v && v.length <= x) || this.$t("fields.rules.max", { c: x })
      }
    };
  },
  props: {
    addOrUpdating: Number,
    layerToEdit: Object
  },
  computed: {
    formTitleLayer() {
      return this.layerToEdit.name
        ? "Modification de la couche"
        : "Nouvelle couche";
    }
  },
  watch: {
    $layerToEdit: {
      handler() {
        if (this.addOrUpdating == 2) {
          this.layerCreated = true;
          this.editedLayer = Object.assign({}, this.layerToEdit);
          if (!this.editedLayer.attributes) this.editedLayer.attributes = {};
          LegendServices.getLegendByLayer(this.editedLayer.id).then(res => {
            this.editedLayer.legend = res.data;
            this.editedLayer = Object.assign({}, this.editedLayer);
          });
        }
        if (this.addOrUpdating == 1) {
          this.layerCreated = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cancelEditing() {
      this.$emit("closeEditingPage");
    },
    handleKeydown(e) {
      if (e.key === "Escape") {
        this.cancelEditing();
      }
    },
    deleteAttribute(item) {
      if (item) {
        confirm(
          "Êtes vous sûr de vouloir supprimer l'attribut " + item + " ?"
        ) && delete this.editedLayer.attributes[item];
      }
      this.editedLayer = Object.assign({}, this.editedLayer);
    },
    setNewAttributeKey(key) {
      let label = this.editedLayer.attributes[key];
      this.editedLayer.attributes[this.attributeKey] = label;
      delete this.editedLayer.attributes[key];
      this.editedLayer = Object.assign({}, this.editedLayer);
    },
    setNewAttribute() {
      if (this.attributeName && this.attributeLabel) {
        if (this.editedLayer.attributes) {
          this.editedLayer.attributes[this.attributeName] = {
            label: this.attributeLabel
          };
          this.attributeName = null;
          this.attributeLabel = null;
        } else {
          this.editedLayer.attributes = {};
          this.editedLayer.attributes[this.attributeName] = {
            label: this.attributeLabel
          };
          this.attributeName = null;
          this.attributeLabel = null;
        }
        this.editedLayer = Object.assign({}, this.editedLayer);
      }
    },
    async saveLayer() {
      let formIsValid = this.$refs.layer_form.validate();
      if (!formIsValid) return;
      if (!this.editedLayer.searchable) {
        this.editedLayer.attributes = null;
        this.editedLayer.title = null;
        this.editedLayer.key = null;
      }

      if (this.editedLayer.searchable)
        Object.keys(this.editedLayer.attributes).forEach(e => {
          if (this.editedLayer.attributes[e] == "" || e == "")
            delete this.editedLayer.attributes[e];
        });
      if (this.addOrUpdating == 2)
        try {
          await LayersServices.putLayer(this.editedLayer);
          this.$toast.success("Couche mis à jour");
          this.$emit("fillArray");
        } catch (e) {
          this.$toast.error(this.$t("toaster.error") + e);
        }
      if (this.addOrUpdating == 1)
        try {
          let res = await LayersServices.postLayer(this.editedLayer);
          this.editedLayer = res.data;
          this.$toast.success("Couche créé");
          this.$emit("fillArray");
        } catch (e) {
          this.$toast.error(this.$t("toaster.error") + e);
        }
    },
    async addLegend() {
      let formIsValid = this.$refs.legend_form.validate();
      if (!formIsValid) return;
      this.newLegend.layer_id = this.editedLayer.id;
      try {
        let legend = await LegendServices.postLegend(this.newLegend);
        this.editedLayer.legend.push(legend.data);
        this.newLegend = Object.assign({}, this.defaultLegend);
        this.cardAddLegend = false;
        this.$toast.success("Légende ajouté");
      } catch (e) {
        this.$toast.error(this.$t("toaster.error") + e);
      }
    },
    async deleteLegend(item) {
      try {
        confirm(
          "Êtes vous sûr de vouloir supprimer la légende " +
            item.legend_text +
            " ?"
        ) && (await LegendServices.deleteLegend(item.legend_id));
        this.$toast.success("Légende supprimé");
        let i = this.editedLayer.legend.findIndex(
          a => a.legend_id == item.legend_id
        );
        this.editedLayer.legend.splice(i, 1);
      } catch (e) {
        this.$toast.error(this.$t("toaster.error") + e);
      }
    },
    async addTheme() {
      let formIsValid = this.$refs.theme_form.validate();
      if (!formIsValid) return;
      try {
        let theme = await ThemeServices.postTheme({ name: this.newThemeName });
        this.newThemeName = null;
        this.srcTheme.push(theme.data);
      } catch (e) {
        this.$toast.error(this.$t("toaster.error") + e);
      }
    },
    async deleteTheme(item) {
      try {
        confirm(
          "Êtes vous sûr de vouloir supprimer le thème " + item.name + " ?"
        ) && (await ThemeServices.deleteTheme(item.id));
        let i = this.srcTheme.findIndex(a => a.id == item.id);
        this.srcTheme.splice(i, 1);
        this.$toast.success("Thème supprimé");
      } catch (e) {
        this.$toast.error(this.$t("toaster.error") + e);
      }
    }
  },
  created() {
    ThemeServices.getThemeList().then(res => (this.srcTheme = res.data));
    window.addEventListener("keyup", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeydown);
  }
};
</script>
