<template>
  <v-card
    v-if="display"
    outlined
    width="550"
    height="90%"
    class="mx-auto overflow-y-auto overflow-x-hidden card_site_info"
    elevation="10"
  >
    <v-btn icon top left small @click="closePopup()">
      <v-icon size="15">mdi-close</v-icon>
    </v-btn>
    <v-card-title>
      <v-row v-if="idFeature != -1">
        <v-col cols="9" class="pa-2 pb-0 pt-0">
          <span>
            {{ featurePopupTitle }}
          </span>
        </v-col>
        <v-col cols="3" class="pa-2 pb-0 pt-0" align-self="start">
          <v-chip
            v-if="this.keyLayer != 'sites_en_friche'"
            :key="gisement_statut"
            :color="
              gisement_statut === 'A qualifier'
                ? 'blue'
                : gisement_statut == 'Friche'
                ? 'red'
                : 'grey'
            "
            outlined
            pill
          >
            {{ gisement_statut }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <span>
          {{ featurePopupTitle }}
        </span>
      </v-row>
    </v-card-title>
    <v-card-text class="pt-0 pb-0">
      <v-row>
        <v-col cols="6" class="pa-2 pb-0">
          {{ $t("mapPopup.source") }} : {{ nameLayer }}
        </v-col>
        <v-col cols="6" class="pa-2 pb-0" align="right" v-if="idFeature != -1">
          {{ avis_date_created }}
        </v-col>
      </v-row>
    </v-card-text>

    <v-container class="pa-2">
      <v-row dense>
        <v-col cols="12">
          <v-tabs
            v-model="model_tab_data"
            light
            background-color="blue-grey lighten-4"
            grow
            v-if="idFeature != -1"
          >
            <v-tab v-for="tab_name in tabs_name" :key="tab_name">
              <v-btn
                v-if="tab_name == 'Avis'"
                color="silver"
                elevation="2"
                fab
                right
                absolute
                x-small
                @click="form = !form"
              >
                <v-icon v-if="form == false">mdi-plus</v-icon>
                <v-icon v-if="form == true">mdi-minus</v-icon>
              </v-btn>
              <v-badge
                v-if="tab_name == 'Avis' && data_avis.length != 0"
                color="primary"
                :content="data_avis.length"
              >
                {{ tab_name }}
              </v-badge>
              <span v-else @click="form = false">
                {{ tab_name }}
              </span>
            </v-tab>
          </v-tabs>
          <v-card-text class="pa-0">
            <v-simple-table dense v-if="idFeature == -1">
              <template v-slot:default>
                <tbody>
                  <tr v-for="feature in featuresPopup" :key="feature.label">
                    <td>{{ feature.label }}</td>
                    <td>{{ feature.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-tabs-items v-model="model_tab_data" v-if="idFeature != -1">
              <v-tab-item v-for="tab_name in tabs_name" :key="tab_name">
                <v-simple-table dense v-if="tab_name == 'Données fournies'">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="feature in featuresPopup" :key="feature.label">
                        <td>{{ feature.label }}</td>
                        <td>{{ feature.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <template v-if="tab_name == 'Avis'">
                  <template v-if="form">
                    <v-row>
                      <v-col justify="center" align="center" class="ma-1">
                        <v-btn-toggle v-model="avis_statut" mandatory>
                          <v-btn value="Friche" large>
                            {{ $t("mapPopup.avis1") }}
                          </v-btn>
                          <v-btn value="En activité" large>
                            {{ $t("mapPopup.avis2") }}
                          </v-btn>
                          <v-btn value="Recyclé" large>
                            {{ $t("mapPopup.avis4") }}
                          </v-btn>
                          <v-btn value="A qualifier" large>
                            {{ $t("mapPopup.avis3") }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col justify="center" align="center">
                        <v-btn-toggle v-model="avis_type" rounded mandatory>
                          <v-btn value="Contrôle visuel" small>
                            {{ $t("mapPopup.how1") }}
                          </v-btn>
                          <v-btn value="Visite terrain" small>
                            {{ $t("mapPopup.how2") }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row v-if="avis_type == 'Contrôle visuel'">
                      <v-col cols="5" align="right">
                        {{ $t("mapPopup.visit") }}
                      </v-col>
                      <v-col cols="6">
                        <v-btn-toggle v-model="avis_visit" rounded mandatory>
                          <v-btn value="OUI" x-small>
                            {{ $t("mapPopup.yes") }}
                          </v-btn>
                          <v-btn value="NON" x-small>
                            {{ $t("mapPopup.no") }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <!--<v-row
                      v-if="avis_type == 'Visite terrain'"
                      justify="center"
                      align="center"
                    >
                      <v-col cols="10">
                        <v-file-input
                          label="Ajouter image"
                          multiple
                          dense
                          show-size
                          v-model="imgs"
                          prepend-icon="mdi-camera"
                        ></v-file-input>
                      </v-col>
                    </v-row>-->
                    <v-row justify="center" align="center">
                      <v-col cols="10">
                        <v-textarea
                          counter="150"
                          v-model="avis_comment"
                          label="Ajouter un commentaire à votre avis"
                          filled
                          no-resize
                          rows="4"
                          shaped
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="ma-1">
                      <v-btn
                        :disabled="
                          avis_comment.length > 150 || avis_comment.length == 0
                        "
                        small
                        @click="send"
                        >{{ $t("mapPopup.send") }}</v-btn
                      >
                    </v-row>
                  </template>
                  <v-divider></v-divider>
                  <v-row v-if="!form">
                    <v-virtual-scroll
                      v-if="data_avis.length != 0"
                      :items="data_avis"
                      :item-height="130"
                      max-height="260"
                      :key="data_avis.imgs"
                    >
                      <template v-slot:default="{ item }">
                        <v-list-item>
                          <v-list-item-content
                            ><v-list-item-title
                              v-html="
                                item.type +
                                  ' du ' +
                                  translateDate(item.created_at)
                              "
                            ></v-list-item-title>
                            <v-col cols="8">
                              <span style="font-weight: bold">
                                {{ item.user_id }} :
                              </span>
                              <span
                                :style="
                                  item.notice == 'Friche'
                                    ? 'color:red'
                                    : item.notice == 'A qualifier'
                                    ? 'color:blue'
                                    : 'color:grey'
                                "
                                >{{ item.notice }}</span
                              >
                            </v-col>
                            <v-col cols="3">
                              <v-row justify="end">
                                <v-chip
                                  v-if="item.type == 'Visite terrain'"
                                  color="blue"
                                  outlined
                                  small
                                  @click="getImgs(item.imgs)"
                                  :disabled="item.imgs.length == 0"
                                >
                                  {{ item.imgs ? item.imgs.length : 0 }}
                                  {{ $t("mapPopup.picture") }}
                                </v-chip>
                              </v-row>
                            </v-col>
                            <v-container class="grey lighten-5">
                              {{ item.comment }}
                            </v-container>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                      </template>
                    </v-virtual-scroll>
                  </v-row>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-col>
        <v-col cols="12" v-if="idFeature != -1">
          <v-tabs
            v-model="model_tab_street_view"
            light
            background-color="blue-grey lighten-4"
            grow
          >
            <v-tab v-for="tab_name in tabs_street_view_name" :key="tab_name">
              {{ tab_name }}</v-tab
            >
          </v-tabs>
          <v-tabs-items v-model="model_tab_street_view">
            <v-tab-item
              v-for="tab_name in tabs_street_view_name"
              :key="tab_name"
            >
              <v-col align-self="center">
                <MapMapillary
                  v-if="tab_name == 'Mapillary'"
                  :coordinate="coordinate"
                  :active="idFeature != -1"
                ></MapMapillary>
                <div v-if="tab_name == 'Google Street View'">
                  <MapStreetView
                    v-if="tab_name == 'Google Street View'"
                    :coordinate="coordinate"
                    :active="idFeature != -1"
                  ></MapStreetView>
                  <v-btn plain block @click="openStreetView()"
                    >Localiser avec Google Street View dans un nouvel
                    onglet</v-btn
                  >
                </div>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-btn color="primary" @click="closePopup()">{{
            $t("buttons.close")
          }}</v-btn>
        </v-col>
        <v-col cols="2">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="rgb(146, 43, 33)"
                @click="openStreetView()"
                v-bind="attrs"
                v-on="on"
              >
                mdi-map-marker-radius-outline
              </v-icon>
            </template>
            <span>{{ $t("mapPopup.tooltipStreetView") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="viewImg">
      <v-carousel
        :show-arrows="imgs.length != 1"
        height="max-content"
        :hide-delimiter-background="imgs.length == 1"
      >
        <v-carousel-item
          v-for="(item, i) in imgs"
          :key="i"
          :src="item.path"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <v-btn color="grey" dark @click="viewImg = false" right small fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-card>
</template>
<style lang="css" scoped>
.card_site_info {
  opacity: 0.8;
  z-index: 6;
  position: absolute;
  right: 1px;
  top: 60px;
  bottom: 5px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  padding-left: 10px;
  padding-bottom: 0px;
  width: 100%;
}
.disable-events {
  pointer-events: none;
}
</style>
<script>
import MapMapillary from "./MapMapillary";
import MapStreetView from "./MapStreetView";
import local from "../../locales/fr.json";
import ComsServices from "../../services/comments";
import ImgServices from "../../services/image";
import { integer } from "vee-validate/dist/rules";

export default {
  name: "MapPopup",
  components: {
    MapMapillary,
    MapStreetView
  },
  data() {
    return {
      height: null,
      avis_date_created: "",
      idGisRef: null,
      setName: false,
      viewImg: false,
      form: false,
      imgAsUrl: null,
      avis_statut: "Ne sais pas",
      avis_type: "Contrôle visuel",
      imgs: [],
      avis_visit: "NON",
      avis_comment: "",
      data_avis: [],
      type: ["Etude Terrain", "Spécification", "Etude foncier"],
      tabs_name:
        this.keyLayer == "sites_en_friche"
          ? ["Données fournies", "Avis"]
          : ["Avis", "Données fournies"],
      model_tab_data: null,
      tabs_street_view_name: ["Google Street View", "Mapillary"],
      model_tab_street_view: null,
      gisement_statut: "A qualifier",
      idSiteFriche: null
    };
  },
  props: {
    coordinate: Array,
    display: Boolean,
    featuresPopup: { type: Array, required: true },
    featurePopupTitle: String,
    idFeature: integer,
    nameLayer: String,
    keyLayer: String
  },
  methods: {
    getImgs(imgs) {
      this.imgs = [];
      this.viewImg = true;
      imgs.forEach(img => {
        let imgTmp = {};
        imgTmp.name = img.name;
        imgTmp.path =
          local.mapPopup.path_image + "/" + img.comment_id + "/" + img.name;
        this.imgs.push(imgTmp);
      });
    },
    translateDate(date) {
      let tmp = date.split("T");
      let d = tmp[0].split("-");
      return d[2] + "/" + d[1] + "/" + d[0];
    },

    setComs(data) {
      if (this.keyLayer != "sites_en_friche") this.idGisRef = data.id;
      ComsServices[
        this.keyLayer == "sites_en_friche"
          ? "getCommentsBySiteId"
          : "getCommentsByGisementId"
      ](data.id)
        .then(response => {
          if (response.data.length > 0 && response.data[0].site_id != null) {
            this.idSiteFriche = response.data[0].site_id;
          }
          if (response.data.length > 0)
            this.gisement_statut = response.data[0].notice;
          let datesArray = [];
          response.data.forEach(com => {
            let tmp = com;
            tmp.imgs = [];
            if (
              com.notice == "En activité" ||
              com.notice == "Friche" ||
              com.notice == "Recyclé"
            )
              datesArray.push(com.created_at);
            if (com.type == "Visite terrain") {
              ImgServices.getImages(com.id)
                .then(response => {
                  tmp.imgs = response.data;
                })
                .catch(err => {
                  this.$toast.error(err);
                  console.log(err);
                });
            }
            if (datesArray.length == 1)
              this.avis_date_created = this.translateDate(datesArray[0]);
            if (datesArray.length > 2)
              this.avis_date_created = this.translateDate(
                datesArray.reduce(function(a, b) {
                  return a > b ? a : b;
                })
              );
            tmp.user_id = com.User.first_name + " " + com.User.last_name;
            this.avis_comment = "";
            this.imgs = [];

            this.data_avis.push(com);
          });
        })
        .catch(error => {
          this.$toast.error(this.$t("toaster.error"));
          console.log(error);
        });
    },
    closePopup: function() {
      this.$emit("closePopup");
    },
    openStreetView: function() {
      this.$emit("openStreetView");
    },
    refreshLayer: function(sKeyLayer) {
      this.$emit("refreshLayer", sKeyLayer);
    },
    updateLayerSite: function() {
      this.$emit("updateLayerSite");
    },
    showSuperAdminPage() {
      if (this.$store.state.auth.user && this.$store.state.auth.user.roles) {
        return this.$store.state.auth.user.roles.includes("SUPERADMIN");
      } else return false;
    }
  },
  watch: {
    idFeature: function() {
      if (this.idFeature != -1) {
        this.height = window.innerHeight;
        this.gisement_statut = "A qualifier";
        this.data_avis = [];
        this.avis_date_created = "";
        this.avis_statut = "Ne sais pas";
        this.avis_type = "Contrôle visuel";
        this.avis_visit = "NON";
        this.avis_comment = "";
        this.imgs = [];
        this.fillComs();
        this.setName = false;
      }
    },
    keyLayer: function() {
      this.tabs_name =
        this.keyLayer == "sites_en_friche"
          ? ["Données fournies", "Avis"]
          : ["Avis", "Données fournies"];
    }
  }
};
</script>
