<template>
  <v-card
    v-if="display"
    class="mx-left card_map_print"
    max-width="550"
    max-height="500"
    elevation="10"
  >
    <v-card-title>
      {{ $t("printMap.title")
      }}<v-btn icon absolute right small @click="closePrint()"
        ><v-icon size="15">mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-container class="pa-0" fluid>
        <v-row align="center">
          <v-col class="d-flex" cols="12">
            <v-select
              v-model="select_layout"
              :items="layouts"
              item-text="name"
              item-value="value"
              label="Mise en page"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="d-flex" cols="12">
            <v-select
              v-model="select_resolution"
              :items="resolutions"
              item-text="name"
              item-value="value"
              label="Résolution"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="d-flex" cols="12">
            <v-text-field
              prefix="1:"
              v-model.number="select_scale"
              label="Échelle"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="primary" @click="printMap()">{{
        $t("buttons.print")
      }}</v-btn>
      <v-btn text color="primary" @click="closePrint()">{{
        $t("buttons.close")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="css" scoped>
.card_map_print {
  z-index: 10;
  width: 15%;
  position: absolute;
  left: 57px;
  top: 1px;
}
</style>

<script>
export default {
  data() {
    return {
      select_layout: "a4",
      select_resolution: 200,
      select_scale: 25000,
      layouts: [
        { name: "A0 (long)", value: "a0" },
        { name: "A1", value: "a1" },
        { name: "A2", value: "a2" },
        { name: "A3", value: "a3" },
        { name: "A4", value: "a4" },
        { name: "A5 (rapide)", value: "a5" }
      ],
      resolutions: [
        { name: "72 dpi (rapide)", value: 72 },
        { name: "150 dpi", value: 150 },
        { name: "200 dpi", value: 200 },
        { name: "300 dpi (long)", value: 300 }
      ],
      dims: {
        a0: [1189, 841],
        a1: [841, 594],
        a2: [594, 420],
        a3: [420, 297],
        a4: [297, 210],
        a5: [210, 148]
      }
    };
  },
  props: {
    display: Boolean,
    scale: Number
  },
  watch: {
    display: function(val) {
      if (val) this.select_scale = this.scale;
    }
  },
  methods: {
    closePrint: function() {
      this.$emit("closePrint");
    },
    printMap: function() {
      this.$emit(
        "printMap",
        this.dims[this.select_layout],
        this.select_resolution,
        this.select_scale
      );
    }
  }
};
</script>
