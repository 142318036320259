import http from "../http-common";
import authHeader from "./auth-header";

class LayerService {
  getLayerList() {
    return http.get("layer", { headers: authHeader() });
  }
  getLayer(layer_id) {
    return http.get("layer/" + layer_id, { headers: authHeader() });
  }
  postLayer(layer) {
    return http.post(
      "layer",
      {
        name: layer.name,
        title: layer.title,
        key: layer.key,
        searchable: layer.searchable,
        url: layer.url,
        geoserver_layer: layer.geoserver_layer,
        attributes: layer.attributes,
        key_layer: layer.key_layer,
        max_zoom: layer.max_zoom,
        min_zoom: layer.min_zoom,
        opacity: layer.opacity,
        visible: layer.visible,
        theme_id: layer.theme_id
      },
      {
        headers: authHeader()
      }
    );
  }
  putLayer(layer) {
    return http.put(
      "layer/" + layer.id,
      {
        name: layer.name,
        title: layer.title,
        key: layer.key,
        searchable: layer.searchable,
        url: layer.url,
        geoserver_layer: layer.geoserver_layer,
        attributes: layer.attributes,
        key_layer: layer.key_layer,
        max_zoom: layer.max_zoom,
        min_zoom: layer.min_zoom,
        opacity: layer.opacity,
        visible: layer.visible,
        theme_id: layer.theme_id
      },
      {
        headers: authHeader()
      }
    );
  }
  deleteLayer(id) {
    return http.delete("layer/" + id, { headers: authHeader() });
  }
}

export default new LayerService();
