<template>
  <div ref="map-root" class="map">
    <MapMenu
      @openIndicator="openIndicator"
      @openLegend="openLegend"
      @openLayersControl="openLayersControl"
      @openTool="openTool"
      @openPrintParam="openPrintParam"
    ></MapMenu>
    <v-card
      v-if="displayLayersControl"
      class="mx-left card-map-layer"
      elevation="10"
      @mouseover="toolsLayer = true"
      @mouseleave="
        transparantOpen.some(e => e) ? (toolLayer = true) : (toolsLayer = false)
      "
    >
      <v-card-title>
        {{ $t("layersControl.title") }}
        <v-btn icon absolute right small @click="displayLayersControl = false">
          <v-icon size="15"> mdi-close </v-icon></v-btn
        >
      </v-card-title>
      <draggable
        :list="layersList"
        :disabled="!enabled"
        ghost-class="ghost"
        @start="dragging = true"
        @end="endDragLayer"
      >
        <template v-for="(item, i) in layersList">
          <v-list-item dense :key="`item-${i}`" :value="item.get('name')">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.get('name')"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action
                v-if="getLayerSearchable(item.get('key_layer')) && toolsLayer"
                style="margin: 0px"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small
                      >mdi-cursor-default-click</v-icon
                    >
                  </template>
                  <span>Couche interrogeable</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action style="margin: 0px" v-if="toolsLayer">
                <v-menu bottom left v-model="transparantOpen[i]">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-badge
                        bordered
                        overlap
                        dot
                        color="accent"
                        v-if="item.getOpacity() < 1"
                        ><v-icon small>mdi-opacity</v-icon></v-badge
                      >
                      <v-icon v-else small>mdi-opacity</v-icon>
                    </v-btn>
                  </template>
                  <v-card elevation="2">
                    <v-card-subtitle style="padding: 5px"
                      >Transparence de la couche :</v-card-subtitle
                    >
                    <v-card-text style="padding: 5px">
                      <v-slider
                        v-model="opacityLayers[i]"
                        :disabled="!getLayerVisible(i)"
                        @end="setLayerOpacity(i)"
                        min="1"
                        max="100"
                        dense
                        hide-details
                      >
                        <template v-slot:append>
                          <v-text-field
                            v-model="opacityLayers[i]"
                            suffix="%"
                            class="mt-0 pt-0"
                            disabled
                            hide-details
                            single-line
                            type="number"
                            style="width: 53px; font-size: 14px"
                          ></v-text-field>
                        </template>
                      </v-slider>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-list-item-action>
              <v-list-item-action style="margin: 0px">
                <v-checkbox
                  v-model="selectedLayers"
                  :value="item.get('key_layer')"
                  @change="displayLayer(i)"
                  dense
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
        <v-divider></v-divider>
        <v-list-group :key="-1" :value="false" active-class="accent">
          <template v-slot:activator>
            <v-list-item-avatar size="25"
              ><v-icon size="15" class="grey lighten-1" dark
                >mdi-folder</v-icon
              ></v-list-item-avatar
            >
            <v-list-item-title>{{
              $t("layersControl.background")
            }}</v-list-item-title>
          </template>
          <v-radio-group :mandatory="true" v-model="selectedBackgroundMap">
            <template v-for="(item, i) in backgroundMapList">
              <v-list-item dense :key="`item-${i}`">
                <v-list-item-avatar
                  ><v-img :src="item.get('avatar')"></v-img
                ></v-list-item-avatar>
                <v-list-item-title
                  v-text="item.get('name')"
                ></v-list-item-title>
                <v-list-item-action>
                  <v-radio
                    :value="item.get('name')"
                    :key="`item-${i}`"
                    color="secondary darken-2"
                  ></v-radio>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-radio-group>
        </v-list-group>
      </draggable>
    </v-card>
    <MapIndicator
      :display="displayIndicator"
      :countAdresseSite="indicator.countAdresseSite"
      :countAdresseParcelle="indicator.countAdresseParcelle"
      :countAdresseUf="indicator.countAdresseUf"
      @closeIndicator="closeIndicator"
    ></MapIndicator>
    <MapLegend
      :display="displayLegend"
      :layer="layerLegendWithTheme"
      :layerWithoutTheme="layerLegendWithoutTheme"
      @closeLegend="closeLegend"
    ></MapLegend>
    <MapTool
      :display="displayTool"
      @closeTool="closeTool"
      @initToolMeasure="initToolMeasure"
      @removeToolMeasure="removeToolMeasure"
      @cleanToolMeasureLayer="cleanToolMeasureLayer"
    ></MapTool>
    <MapPopup
      :coordinate="clickCoordinate"
      :display="displayPopup"
      :featuresPopup="featurePopup.features"
      :featurePopupTitle="featurePopup.title"
      :idFeature="featurePopup.id"
      :nameLayer="featurePopup.layer"
      :keyLayer="featurePopup.keyLayer"
      @refreshLayer="refreshLayer"
      @updateLayerSite="updateLayerSite"
      @openStreetView="openStreetView"
      @closePopup="closePopup"
      ref="MapPopup"
    ></MapPopup>
    <MapPopupList
      :display="displayPopupList"
      :featuresPopupList="featuresPopupList.features"
      :featurePopupListTitle="featuresPopupList.title"
      @closePopupList="closePopupList"
      @getPopup="getPopup"
      ref="mappopuplist"
    ></MapPopupList>
    <MapPrint
      :display="displayPrintParam"
      :scale="scale"
      @closePrint="closePrint"
      @printMap="printMap"
    ></MapPrint>
    <SelectLocation @zoomToSelected="zoomToSelected"></SelectLocation>
  </div>
</template>
<style lang="css" scoped>
.map {
  width: 100%;
  height: 100%;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.card-map-layer {
  z-index: 10;
  width: 15%;
  position: absolute;
  left: 57px;
  top: 1px;
}
</style>
<style lang="css">
.map .ol-custom-overviewmap,
.map .ol-custom-overviewmap.ol-uncollapsible {
  bottom: 2.7em;
  left: auto;
  right: 0.5em;
  top: auto;
}
.map .ol-custom-overviewmap:not(.ol-collapsed) {
  border: 1px solid black;
}
.map .ol-custom-overviewmap .ol-overviewmap-map {
  border: none;
  width: 300px;
}

.map .ol-custom-overviewmap .ol-overviewmap-box {
  border: 2px solid red;
}

.map .ol-custom-overviewmap:not(.ol-collapsed) button {
  bottom: auto;
  left: auto;
  right: 1px;
  top: 1px;
}
.map .ol-zoom {
  left: auto;
  right: 0.5em;
  top: 4em;
}
.map .ol-zoom-extent {
  left: auto;
  right: 0.5em;
  top: 8em;
}
.map .ol-rotate {
  top: 10.4em;
}
.ol-control button,
.ol-scale-line {
  background-color: var(--v-secondary-base);
}
.ol-control button:hover,
.ol-control button:focus {
  background-color: var(--v-secondary-darken2);
}
.ol-custom-button-zoom-extent button {
  background: center url(/home.png) no-repeat;
  background-color: var(--v-secondary-base);
}
/* measure tool*/
.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}
</style>

<script>
import axios from "axios";
import * as source from "ol/source";
import * as format from "ol/format";
import * as extent from "ol/extent";
import LayerGroup from "ol/layer/Group";
import {
  fromLonLat,
  getPointResolution,
  transform,
  get as getProjection
} from "ol/proj";
import Overlay from "ol/Overlay";
import {
  OverviewMap,
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls
} from "ol/control";
import {
  DragRotateAndZoom,
  defaults as defaultInteractions,
  Draw
} from "ol/interaction";
import { getArea, getLength } from "ol/sphere";
import { unByKey } from "ol/Observable";
import { LineString, Polygon } from "ol/geom";
import draggable from "vuedraggable";
import {
  oSourceMeasure,
  oLayerMeasure,
  oStyleMeasureDraw
} from "../../config/layer_tool";
import { backgroundMapList } from "../../config/layer_background";
import { getLayerList } from "../../config/map_layers";
import {
  oSourceSite,
  oSourceSiteUf,
  oSourceSiteParcelle
} from "../../config/layer";
//import MapService from "../../services/map";
import SiteService from "../../services/site";
import MapMenu from "./MapMenu";
import MapIndicator from "./MapIndicator";
import MapLegend from "./MapLegend";
import MapTool from "./MapTool";
import MapPopup from "./MapPopup";
import MapPopupList from "./MapPopupList";
import SelectLocation from "./SelectLocation";
import MapPrint from "./MapPrint";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image-more";
import { mapState } from "vuex";

export default {
  name: "BaseMap",
  components: {
    draggable,
    MapMenu,
    MapIndicator,
    MapLegend,
    MapTool,
    MapPopup,
    MapPopupList,
    SelectLocation,
    MapPrint
  },
  data() {
    return {
      transparantOpen: [],
      toolsLayer: false,
      clickCoordinate: null,
      clickPixel: null,
      layersList: null,
      backgroundMapList: backgroundMapList,
      selectedLayers: [],
      selectedBackgroundMap: "",
      opacityLayers: [],
      map: null,
      mapProjection: "EPSG:2154",
      mapConfig: null,
      scaleLine: null,
      scale: null,
      dragging: false,
      enabled: true,
      overlayPopup: null,
      overlayPopupList: null,
      displayPopup: false,
      displayPopupList: false,
      featurePopup: {
        features: [],
        title: "",
        source: "",
        keyLayer: "",
        id: null
      },
      featuresPopupList: { features: [], title: "" },
      displayLayersControl: false,
      displayPrintParam: false,
      displayLegend: false,
      displayIndicator: true,
      displayTool: false,
      layerLegendWithTheme: [],
      layerLegendWithoutTheme: [],
      indicator: {
        countAdresseSite: null,
        countAdresseParcelle: null,
        countAdresseUf: null
      },
      measure: {
        sketch: null,
        helpTooltipElement: null,
        helpTooltip: null,
        tooltipElement: null,
        tooltip: null,
        listener: null,
        draw: null
      }
    };
  },
  computed: {
    count() {
      return this.$store.state.openMenu;
    },
    ...mapState({
      mapStore: state => state.map
    })
  },
  watch: {
    count() {
      this.displayLayersControl = false;
      if (this.displayLegend) this.closeLegend();
      if (this.displayPopup) this.closePopupList();
      if (this.displayTool) this.closeTool();
      if (this.displayPrintParam) this.closePrint();
      if (this.displayIndicator) this.closeIndicator();
    },
    selectedBackgroundMap: function(sLayerName) {
      this.backgroundMapList.forEach(layer => {
        if (layer.get("name") == sLayerName) layer.setVisible(true);
        else layer.setVisible(false);
      });
    },
    // Détecte chaque changement sur la carte dans le store
    mapStore: {
      handler: (newProp, oldProp) => {
        newProp;
        oldProp;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getLayerByKeyLayer(sKeyLayer) {
      let oLayer = this.layersList.find(
        layer_tmp => layer_tmp.get("key_layer") === sKeyLayer
      );
      if (oLayer instanceof LayerGroup)
        for (let oLayerGroup of oLayer.getLayers().getArray())
          if (oLayerGroup.get("key_layer") == sKeyLayer) oLayer = oLayerGroup;
      return oLayer;
    },
    getLayerConfigByName(sLayerName) {
      for (let index = 0; index < this.mapConfig.Layers.length; index++) {
        if (this.mapConfig.Layers[index].name == sLayerName)
          return this.mapConfig.Layers[index];
      }
      for (let index = 0; index < this.mapConfig.LayerGroups.length; index++) {
        if (this.mapConfig.LayerGroups[index].name == sLayerName)
          return this.mapConfig.LayerGroups[index];
      }
    },
    getLayerConfigByKey(sKeyLayer) {
      for (let index = 0; index < this.mapConfig.Layers.length; index++) {
        if (this.mapConfig.Layers[index].key_layer == sKeyLayer)
          return this.mapConfig.Layers[index];
      }
      for (let index = 0; index < this.mapConfig.LayerGroups.length; index++) {
        if (this.mapConfig.LayerGroups[index].key_layer == sKeyLayer)
          return this.mapConfig.LayerGroups[index];
      }
    },
    actualizeZIndex() {
      const size = this.layersList.length;
      this.layersList.forEach((layer, idx) => {
        layer.setZIndex(size - idx);
      });
    },
    // hack to force notify, MUST change when version of map with catalog + layer list
    actualizeDisplayLayers() {
      this.selectedLayers = this.selectedLayers.slice();
    },
    endDragLayer() {
      this.dragging = false;
      this.actualizeZIndex();
      this.actualizeDisplayLayers();
    },
    displayLayer(iIndexLayer) {
      const oLayer = this.layersList[iIndexLayer];
      oLayer.setVisible(!oLayer.getVisible());
      if (oLayer instanceof LayerGroup)
        for (let oLayerGroup of oLayer.getLayers().getArray())
          oLayerGroup.setVisible(!oLayerGroup.getVisible());
    },
    getLayerVisible(indexLayer) {
      return this.layersList[indexLayer].getVisible();
    },
    getLayerSearchable(sKeyLayer) {
      try {
        return this.getLayerConfigByKey(sKeyLayer).searchable ? true : false;
      } catch (e) {
        this.$toast.error(
          "La couche '" +
            sKeyLayer +
            "' n'est pas associer à la carte! Incohérence avec le fichier config/map_layers.js"
        );
      }
    },
    setLayerOpacity(indexLayer) {
      this.layersList[indexLayer].setOpacity(
        this.opacityLayers[indexLayer] / 100
      );
    },
    /**
     * Return the first (highest z-index) layer to be instance of TileWMS or Vector
     * i.e. the highest on which getFeatureInfo can be perform
     * @returns {TileLayer}
     */
    getDefaultSearchableLayer() {
      let layerToReturn = null;
      this.layersList.every(layer => {
        if (
          this.getLayerSearchable(layer.get("key_layer")) &&
          layer.getVisible()
        ) {
          if (layer instanceof LayerGroup) {
            for (let oLayerFromGroup of layer.getLayers().getArray())
              if (oLayerFromGroup.get("key_layer") == layer.get("key_layer"))
                layerToReturn = oLayerFromGroup;
            return false;
          } else if (
            layer.getSource() instanceof source.TileWMS ||
            layer.getSource() instanceof source.Vector
          ) {
            layerToReturn = layer;
            return false;
          }
        }
        return true;
      });
      return layerToReturn;
    },
    refreshLayer(sKeyLayer) {
      this.getLayerByKeyLayer(sKeyLayer)
        .getSource()
        .refresh();
    },
    openPopup() {
      this.closePopupList();
      this.displayPopup = true;
    },
    closePopup() {
      this.displayPopup = false;
    },
    getPopup(sKeyLayer, key) {
      let oLayerConfig = this.getLayerConfigByKey(sKeyLayer);
      // get config if layer is a group
      if (typeof oLayerConfig.Layers !== "undefined") {
        for (let oLayerFromGroup of oLayerConfig.Layers) {
          if (oLayerFromGroup.key_layer == oLayerConfig.key_layer)
            oLayerConfig = oLayerFromGroup;
        }
      }
      this.featurePopup.layer = oLayerConfig.name;
      this.featurePopup.features = [];
      let oLayerSearchable = this.getLayerByKeyLayer(sKeyLayer);
      this.featurePopup.keyLayer = sKeyLayer;
      const aCoordinate = this.overlayPopupList.getPosition();
      if (oLayerSearchable.getSource() instanceof source.TileWMS) {
        const url = oLayerSearchable
          .getSource()
          .getFeatureInfoUrl(
            aCoordinate,
            this.map.getView().getResolution(),
            this.map.getView().getProjection(),
            { INFO_FORMAT: "application/vnd.ogc.gml" }
          );
        axios.get(url).then(response => {
          const parser = new format.WMSGetFeatureInfo();
          let aFeatures = parser.readFeatures(response.data);
          if (aFeatures.length > 0) {
            aFeatures.forEach(oFeature => {
              if (
                this.featurePopup.features.length == 0 &&
                oFeature.getProperties()[oLayerConfig.key] == key
              ) {
                this.setFeaturePopup(oFeature, oLayerConfig);
              }
            });
            this.openPopup();
          }
        });
      } else if (oLayerSearchable.getSource() instanceof source.Vector) {
        /*const aFeatures = oLayerSearchable .getSource() .getFeaturesAtCoordinate(aCoordinate);*/
        let aFeatures = [];
        this.map.forEachFeatureAtPixel(
          this.clickPixel,
          function(feature) {
            if (typeof feature !== "undefined") return aFeatures.push(feature);
          },
          {
            layerFilter: function(layer) {
              return (
                layer.get("key_layer") === oLayerSearchable.get("key_layer")
              );
            }
          }
        );
        if (aFeatures.length > 0) {
          aFeatures.forEach(oFeature => {
            if (
              this.featurePopup.features.length == 0 &&
              oFeature.getProperties()[oLayerConfig.key] == key
            ) {
              this.setFeaturePopup(oFeature, oLayerConfig);
            }
          });
        }
        this.openPopup();
      }
    },
    setFeaturePopup(oFeature, oLayerConfig) {
      this.featurePopup.keyLayer = oLayerConfig.key_layer;
      this.featurePopup.id = -1;
      this.featurePopup.title = oFeature.getProperties()[oLayerConfig.title];
      for (let attribute in oLayerConfig.attributes) {
        if (oFeature.getProperties()[attribute] != null) {
          this.featurePopup.features.push({
            label: oLayerConfig.attributes[attribute].label,
            value: oFeature.getProperties()[attribute]
          });
        }
      }
    },
    setFeaturesPopupList(aFeatures, oLayerConfig) {
      if (aFeatures.length > 0) {
        let features = [];
        let id_features = [];
        let feature_title = "";
        aFeatures.forEach(feature => {
          if (
            id_features.indexOf(feature.getProperties()[oLayerConfig.key]) == -1
          ) {
            if (feature.getProperties()[oLayerConfig.title])
              feature_title = feature
                .getProperties()
                [oLayerConfig.title].substr(0, 65);
            else feature_title = "";
            features.push({
              title: feature_title,
              key: feature.getProperties()[oLayerConfig.key]
            });
            id_features.push(feature.getProperties()[oLayerConfig.key]);
          }
        });
        this.featuresPopupList.features.push({
          layer: oLayerConfig.name,
          key_layer: oLayerConfig.key_layer,
          nb_feature: id_features.length,
          features: features
        });
        return id_features.length;
      } else {
        return 0;
      }
    },
    openPopupList(aCoordinate) {
      this.closePopup();
      this.overlayPopupList.setPosition(aCoordinate);
      this.displayPopupList = true;
    },
    closePopupList() {
      this.displayPopupList = false;
    },
    addLayerToLegend(oLayer, oLayerConfig, iIndexLayer) {
      if (typeof oLayerConfig !== "undefined") {
        let sameTheme = null;
        let newtheme = {};
        if (oLayerConfig.Theme != null)
          newtheme.theme = oLayerConfig.Theme.name;
        else newtheme.theme = oLayerConfig.Theme;
        newtheme.layer = [];
        if (this.layerLegendWithTheme.length > 0) {
          sameTheme = this.layerLegendWithTheme.find(
            layer => layer.theme === newtheme.theme
          );
        }
        if (typeof oLayerConfig.Legends !== "undefined") {
          if (oLayerConfig.Legends.length > 0) {
            oLayerConfig.Legends.forEach(oLegend => {
              if (oLegend.legend_img != null && oLegend.legend_text != null) {
                let src = {};
                src.index = iIndexLayer;
                src.legend_img = oLegend.legend_img;
                src.legend_text = oLegend.legend_text;
                if (sameTheme) {
                  sameTheme.layer.push(src);
                } else {
                  newtheme.layer.push(src);
                  if (newtheme.theme) {
                    if (this.layerLegendWithTheme.indexOf(newtheme) == -1)
                      this.layerLegendWithTheme.push(newtheme);
                  } else {
                    this.layerLegendWithoutTheme.push(src);
                  }
                }
              } else {
                if (oLayer.getSource() instanceof source.TileWMS) {
                  let src = {};
                  src.index = iIndexLayer;
                  src.legend_img = oLayer
                    .getSource()
                    .getLegendUrl(this.map.getView().getResolution(), {
                      SLD_VERSION: "1.1.0"
                    });
                  if (oLegend.legend_text != null)
                    src.legend_text = oLegend.legend_text;
                  if (sameTheme) {
                    sameTheme.layer.push(src);
                  } else {
                    newtheme.layer.push(src);
                    if (newtheme.theme) {
                      if (this.layerLegendWithTheme.indexOf(newtheme) == -1)
                        this.layerLegendWithTheme.push(newtheme);
                    } else {
                      this.layerLegendWithoutTheme.push(src);
                    }
                  }
                }
              }
            });
          }
        } else {
          console.log(
            'Warning : pas de légende pour la couche : "' +
              oLayer.get("key_layer") +
              '"'
          );
          console.log(oLayerConfig);
        }
      }
    },
    openLegend() {
      this.closeTool();
      this.displayLayersControl = false;
      this.displayPrintParam = false;
      this.displayIndicator = false;
      this.layerLegendWithTheme = [];
      this.layerLegendWithoutTheme = [];
      for (let iIndexLayer in this.layersList) {
        if (this.layersList[iIndexLayer].getVisible()) {
          let oLayerConfig = this.getLayerConfigByKey(
            this.layersList[iIndexLayer].get("key_layer")
          );
          if (this.layersList[iIndexLayer] instanceof LayerGroup) {
            for (let oLayerFromGroup of this.layersList[iIndexLayer]
              .getLayers()
              .getArray()) {
              let oLayerFromGroupConfig = oLayerConfig.Layers.find(
                layer_tmp =>
                  layer_tmp.key_layer === oLayerFromGroup.get("key_layer")
              );
              this.addLayerToLegend(
                oLayerFromGroup,
                oLayerFromGroupConfig,
                iIndexLayer
              );
            }
          } else {
            this.addLayerToLegend(
              this.layersList[iIndexLayer],
              oLayerConfig,
              iIndexLayer
            );
          }
        }
      }
      this.displayLegend = true;
    },
    closeLegend() {
      this.displayLegend = false;
      this.layerLegendWithTheme = [];
      this.layerLegendWithoutTheme = [];
    },
    /*updateLegend(e) {
                this.layerLegendWithTheme=layer.getSource().getLegendUrl(event.target.getResolution(), {SLD_VERSION:"1.1.0"})
            },*/
    closePrint() {
      this.displayPrintParam = false;
    },
    openIndicator() {
      this.closeLegend();
      this.displayPrintParam = false;
      this.displayLayersControl = false;
      this.displayIndicator = true;
    },
    closeIndicator() {
      this.displayIndicator = false;
    },
    openTool() {
      this.closeLegend();
      this.displayPrintParam = false;
      this.displayLayersControl = false;
      this.displayIndicator = false;
      this.displayTool = true;
    },
    closeTool() {
      this.removeToolMeasure();
      this.cleanToolMeasureLayer();
      this.displayTool = false;
    },
    zoomToSelected(x, y) {
      const iBuffer = 200;
      const aCoord = fromLonLat([x, y], this.mapProjection);
      const aExtent = [
        aCoord[0] - iBuffer,
        aCoord[1] - iBuffer,
        aCoord[0] + iBuffer,
        aCoord[1] + iBuffer
      ];
      this.map.getView().fit(aExtent);
    },
    getScale() {
      const metersperunit = this.map
        .getView()
        .getProjection()
        .getMetersPerUnit();
      const resolution = this.map.getView().getResolution();
      const inchesPerMetre = 39.37;
      const dpi = 72;
      this.scale = Math.round(
        resolution * metersperunit * inchesPerMetre * dpi
      );
    },
    openLayersControl() {
      this.closeLegend();
      this.closeTool();
      this.displayPrintParam = false;
      this.displayLayersControl = true;
      this.displayIndicator = false;
    },
    openPrintParam() {
      this.closeLegend();
      this.closeTool();
      this.displayLayersControl = false;
      this.getScale(); // update scale value
      this.displayPrintParam = true;
    },
    openStreetView() {
      window.open(
        "https://www.google.com/maps?q&layer=c&cbll=" +
          this.clickCoordinate[1] +
          "," +
          this.clickCoordinate[0] +
          "&cbp"
      );
    },
    printMap(dim, resolution, scale) {
      // https://openlayers.org/en/latest/examples/print-to-scale.html
      scale = scale / 1000; // à vérifier - Fabien
      document.body.style.cursor = "progress";
      const width = Math.round((dim[0] * resolution) / 25.4);
      const height = Math.round((dim[1] * resolution) / 25.4);
      const viewResolution = this.map.getView().getResolution();
      const scaleResolution =
        scale /
        getPointResolution(
          this.map.getView().getProjection(),
          resolution / 25.4,
          this.map.getView().getCenter()
        );
      let exportOptions = {
        filter: function(element) {
          var className = element.className || "";
          return (
            className.indexOf("ol-control") === -1 ||
            className.indexOf("ol-scale") > -1 ||
            (className.indexOf("ol-attribution") > -1 &&
              className.indexOf("ol-uncollapsible"))
          );
        }
      };
      const map = this.map;
      const scaleLine = this.scaleLine;
      const toaster = this.$toast;
      map.once("rendercomplete", function() {
        exportOptions.width = width;
        exportOptions.height = height;
        domtoimage
          .toJpeg(map.getViewport(), exportOptions)
          .then(function(dataUrl) {
            let pdf = new jsPDF("landscape", undefined, format);
            pdf.addImage(dataUrl, "JPEG", 0, 0, dim[0], dim[1]);
            pdf.save("map.pdf");
            // Reset original map size
            scaleLine.setDpi();
            map.getTargetElement().style.width = "";
            map.getTargetElement().style.height = "";
            map.updateSize();
            map.getView().setResolution(viewResolution);
            document.body.style.cursor = "auto";
          })
          .catch(error => {
            document.body.style.cursor = "auto";
            console.error(error);
            toaster.error(error.message);
          });
      });
      // Set print size
      scaleLine.setDpi(resolution);
      map.getTargetElement().style.width = width + "px";
      map.getTargetElement().style.height = height + "px";
      map.updateSize();
      map.getView().setResolution(scaleResolution);
    },
    featureZoom(oLayer, attribute_id, id) {
      let oSource = null;
      if (oLayer instanceof LayerGroup) {
        for (let oLayerGroup of oLayer.getLayers().getArray())
          if (oLayerGroup.get("name") == oLayer.get("name")) {
            oSource = oLayerGroup.getSource();
          }
      } else {
        oSource = oLayer.getSource();
      }
      if (id.length == 1) {
        oSource.getSource().forEachFeature(feature => {
          if (
            this.$store.state.zoomMap.id != null &&
            feature.get(attribute_id) == id
          ) {
            this.map.getView().fit(feature.getGeometry(), {
              size: this.map.getSize(),
              padding: [100, 100, 100, 100]
            });
            this.$store.state.zoomMap.id = null;
            this.$store.state.zoomMap.layer = null;
            this.$store.state.zoomMap.attribute_id = null;
          }
        });
      } else {
        let first = true;
        let minx, miny, maxx, maxy;
        oSource.getSource().forEachFeature(feature => {
          id.forEach(id => {
            if (feature.get(attribute_id) == id) {
              if (first) {
                minx = feature.getGeometry().getExtent()[0];
                miny = feature.getGeometry().getExtent()[1];
                maxx = feature.getGeometry().getExtent()[2];
                maxy = feature.getGeometry().getExtent()[3];
                first = false;
              }
              if (minx > feature.getGeometry().getExtent()[0])
                minx = feature.getGeometry().getExtent()[0];
              if (miny > feature.getGeometry().getExtent()[1])
                miny = feature.getGeometry().getExtent()[1];
              if (maxx < feature.getGeometry().getExtent()[2])
                maxx = feature.getGeometry().getExtent()[2];
              if (maxy < feature.getGeometry().getExtent()[3])
                maxy = feature.getGeometry().getExtent()[3];
            }
          });
        });
        this.map.getView().fit(
          new extent.boundingExtent([
            [minx, miny],
            [maxx, maxy]
          ]),
          {
            size: this.map.getSize(),
            padding: [100, 100, 100, 100]
          }
        );
      }
      this.$store.state.zoomMap.id = null;
      this.$store.state.zoomMap.layer = null;
      this.$store.state.zoomMap.attribute_id = null;
    },
    /*
     * Measure tools
     */
    createHelpTooltip() {
      if (this.measure.helpTooltipElement) {
        this.measure.helpTooltipElement.parentNode.removeChild(
          this.measure.helpTooltipElement
        );
      }
      this.measure.helpTooltipElement = document.createElement("div");
      this.measure.helpTooltipElement.className = "ol-tooltip hidden";
      this.measure.helpTooltip = new Overlay({
        element: this.measure.helpTooltipElement,
        offset: [15, 0],
        id: "measure",
        positioning: "center-left"
      });
      this.map.addOverlay(this.measure.helpTooltip);
    },
    createMeasureTooltip() {
      if (this.measure.tooltipElement) {
        this.measure.tooltipElement.parentNode.removeChild(
          this.measure.tooltipElement
        );
      }
      this.measure.tooltipElement = document.createElement("div");
      this.measure.tooltipElement.className = "ol-tooltip ol-tooltip-measure";
      this.measure.tooltip = new Overlay({
        element: this.measure.tooltipElement,
        offset: [0, -15],
        id: "measure",
        positioning: "bottom-center"
      });
      this.map.addOverlay(this.measure.tooltip);
    },
    initToolMeasure(type) {
      this.measure.draw = new Draw({
        source: oSourceMeasure,
        type: type,
        style: oStyleMeasureDraw
      });
      this.map.addInteraction(this.measure.draw);
      this.createMeasureTooltip();
      this.createHelpTooltip();
      this.measure.listener = null;
      this.measure.draw.on("drawstart", evt => {
        this.measure.sketch = evt.feature;
        let tooltipCoord = evt.coordinate;
        this.measure.listener = this.measure.sketch
          .getGeometry()
          .on("change", evt => {
            const geom = evt.target;
            let output;
            if (geom instanceof Polygon) {
              const area = getArea(geom);
              if (area > 10000) {
                output =
                  Math.round((area / 1000000) * 100) / 100 +
                  " " +
                  "km<sup>2</sup>";
              } else {
                output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
              }
              tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
              const length = getLength(geom);
              if (length > 100) {
                output = Math.round((length / 1000) * 100) / 100 + " " + "km";
              } else {
                output = Math.round(length * 100) / 100 + " " + "m";
              }
              tooltipCoord = geom.getLastCoordinate();
            }
            this.measure.tooltipElement.innerHTML = output;
            this.measure.tooltip.setPosition(tooltipCoord);
          });
      });
      this.measure.draw.on("drawend", () => {
        this.measure.tooltipElement.className = "ol-tooltip ol-tooltip-static";
        this.measure.tooltip.setOffset([0, -7]);
        this.measure.sketch = null;
        this.measure.tooltipElement = null;
        this.createMeasureTooltip();
        unByKey(this.measure.listener);
      });
    },
    removeToolMeasure() {
      this.measure.sketch = null;
      this.measure.tooltip = null;
      this.measure.tooltipElement = null;
      unByKey(this.measure.listener);
      this.map.removeInteraction(this.measure.draw);
    },
    cleanToolMeasureLayer() {
      this.measure.tooltip = null;
      this.measure.tooltipElement = null;
      oSourceMeasure.clear();
      let continueDo = true;
      do {
        continueDo = false;
        this.map
          .getOverlays()
          .getArray()
          .forEach(overlay => {
            if (typeof overlay != "undefined")
              if (overlay.getId() == "measure") {
                this.map.removeOverlay(overlay);
                continueDo = true;
              }
          });
      } while (continueDo == true);
    },
    setLayerFromConfig(oLayer, oLayerConfig) {
      // set name, visible
      oLayer.set("name", oLayerConfig.name);
      oLayer.setVisible(oLayerConfig.visible);
      if (oLayer.getVisible())
        this.selectedLayers.push(oLayer.get("key_layer"));
      // set opacity
      if (
        !!oLayerConfig.opacity &&
        oLayerConfig.opacity >= 0 &&
        oLayerConfig.opacity <= 1
      )
        oLayer.setOpacity(oLayerConfig.opacity);
      this.opacityLayers.push(oLayer.getOpacity() * 100);
      // set Zoom
      if (!!oLayerConfig.min_zoom && oLayerConfig.min_zoom >= 0)
        oLayer.setMinZoom(oLayerConfig.min_zoom);
      if (!!oLayerConfig.max_zoom && oLayerConfig.max_zoom >= 0)
        oLayer.setMaxZoom(oLayerConfig.max_zoom);
      // set url
      if (typeof oLayerConfig.url !== "undefined" && oLayerConfig.url !== null)
        oLayer.getSource().setUrl(oLayerConfig.url);
      // set Geoserver layer name
      if (
        typeof oLayerConfig.geoserver_layer !== "undefined" &&
        oLayerConfig.geoserver_layer !== null
      )
        oLayer
          .getSource()
          .updateParams({ LAYERS: oLayerConfig.geoserver_layer });
    },
    /*getUrlSiteData() {
      return (
        process.env.VUE_APP_GEOSERVER_URL +
        "/" +
        process.env.VUE_APP_GEOSERVER_WORKSPACE +
        "/ows?service=WFS&version=1.0.0&request=GetFeature&maxFeatures=50&outputFormat=application%2Fjson&SRSName=urn:x-ogc:def:crs:EPSG:3857&CQL_FILTER=tiers_id='" +
        this.$store.state.tiersId +
        "'&typeName=" +
        process.env.VUE_APP_GEOSERVER_WORKSPACE +
        ":"
      );
    },
    updateLayerSiteGeom(data) {
      this.layersList[0]
        .getLayers()
        .getArray()[1]
        .getSource()
        .clear();
      const oFeatures = new format.GeoJSON().readFeatures(data, {
        featureProjection: this.map.getView().getProjection()
      });
      if (oFeatures.length > 0 && oFeatures[0].getGeometry() != null) {
        this.layersList[0]
          .getLayers()
          .getArray()[1]
          .getSource()
          .addFeatures(oFeatures);
        this.indicator.countAdresseSite = oFeatures.length;
      }
    },
    updateLayerSiteCluster(data) {
      this.layersList[0]
        .getLayers()
        .getArray()[0]
        .getSource()
        .getSource()
        .clear();
      const oFeatures = new format.GeoJSON().readFeatures(data, {
        featureProjection: this.map.getView().getProjection()
      });
      if (oFeatures.length > 0 && oFeatures[0].getGeometry() != null) {
        this.layersList[0]
          .getLayers()
          .getArray()[0]
          .getSource()
          .getSource()
          .addFeatures(oFeatures);
        this.layersList[0]
          .getLayers()
          .getArray()[0]
          .setStyle(styleFricheFunction);
      }
    },*/
    updateLayerSite() {
      if (this.layersList[0].get("key_layer") == "sites_en_friche") {
        SiteService.getSitesByDep(this.$store.state.selectedDep.insee_dep)
          .then(response => {
            const oGeojson = {
              type: "FeatureCollection",
              crs: {
                type: "name",
                properties: {
                  name: "EPSG:2154"
                }
              },
              features: response.data
            };
            oSourceSite.clear();
            oSourceSite.addFeatures(
              new format.GeoJSON().readFeatures(oGeojson)
            );
            this.indicator.countAdresseSite = 0;
            for (let ofeature of response.data)
              if (ofeature.properties.friche == "Oui")
                this.indicator.countAdresseSite++;
          })
          .catch(error => console.log(error));
      }
    },
    updateLayerSiteParcelle() {
      if (this.layersList[1].get("key_layer") == "sites_en_friche_parcelle") {
        SiteService.getSitesParcelleByDep(
          this.$store.state.selectedDep.insee_dep
        )
          .then(response => {
            const oGeojson = {
              type: "FeatureCollection",
              crs: {
                type: "name",
                properties: {
                  name: "EPSG:2154"
                }
              },
              features: response.data
            };
            oSourceSiteParcelle.clear();
            oSourceSiteParcelle.addFeatures(
              new format.GeoJSON().readFeatures(oGeojson)
            );
            this.indicator.countAdresseParcelle = 0;
            for (let ofeature of response.data)
              if (ofeature.properties.friche == "Oui")
                this.indicator.countAdresseParcelle++;
          })
          .catch(error => console.log(error));
      }
    },
    updateLayerSiteUf() {
      if (this.layersList[2].get("key_layer") == "sites_en_friche_uf") {
        SiteService.getSitesUfByDep(this.$store.state.selectedDep.insee_dep)
          .then(response => {
            const oGeojson = {
              type: "FeatureCollection",
              crs: {
                type: "name",
                properties: {
                  name: "EPSG:2154"
                }
              },
              features: response.data
            };
            oSourceSiteUf.clear();
            oSourceSiteUf.addFeatures(
              new format.GeoJSON().readFeatures(oGeojson)
            );
            this.indicator.countAdresseUf = 0;
            for (let ofeature of response.data)
              if (ofeature.properties.friche == "Oui")
                this.indicator.countAdresseUf++;
          })
          .catch(error => console.log(error));
      }
    }
  },
  mounted() {
    this.mapConfig = this.$store.state.map;
    this.layersList = getLayerList(
      this.$store.state.map.id,
      this.$store.state.selectedDep.insee_dep
    );
    const overviewMapControl = new OverviewMap({
      className: "ol-overviewmap ol-custom-overviewmap",
      layers: [new this.layer.Tile({ source: new source.OSM() })]
    });
    this.scaleLine = new ScaleLine({ units: "metric" });

    const zoomToExtentControl = new ZoomToExtent({
      className: "ol-zoom-extent ol-custom-button-zoom-extent",
      label: "",
      tipLabel: "Zoom à l'étendue par défaut",
      extent: this.mapConfig.default_extent
    });

    this.map = new this.Map({
      target: this.$refs["map-root"],
      controls: defaultControls().extend([
        overviewMapControl,
        this.scaleLine,
        zoomToExtentControl
      ]),
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      view: new this.View({
        center: [
          this.mapConfig.default_center_x,
          this.mapConfig.default_center_y
        ],
        projection: getProjection(this.mapProjection),
        zoom: this.mapConfig.default_zoom,
        maxZoom: 19,
        minZoom: 6,
        constrainResolution: true
      })
    });
    this.overlayPopupList = new Overlay({
      element: this.$refs.mappopuplist.$el
    });
    this.map.addOverlay(this.overlayPopupList);
    this.backgroundMapList
      .slice()
      .reverse()
      .forEach(layer => {
        this.map.addLayer(layer);
        if (layer.getVisible()) this.selectedBackgroundMap = layer.get("name");
      });
    this.map.addLayer(oLayerMeasure);
    this.layersList
      .slice()
      .reverse()
      .forEach(oLayer => {
        let oLayerConfig = this.getLayerConfigByKey(oLayer.get("key_layer"));
        if (typeof oLayerConfig !== "undefined") {
          this.setLayerFromConfig(oLayer, oLayerConfig);
          if (oLayer instanceof LayerGroup) {
            if (typeof oLayerConfig.Layers !== "undefined") {
              // Layer is a Group so set config layer
              for (let oLayerFromGroup of oLayer.getLayers().getArray()) {
                let oLayerFromGroupConfig = oLayerConfig.Layers.find(
                  layer_tmp =>
                    layer_tmp.key_layer === oLayerFromGroup.get("key_layer")
                );
                if (typeof oLayerFromGroupConfig !== "undefined")
                  this.setLayerFromConfig(
                    oLayerFromGroup,
                    oLayerFromGroupConfig
                  );
                else
                  console.log(
                    "Warning : impossible de trouver la couche JS nommée '" +
                      oLayerFromGroup.get("key_layer") +
                      "' du groupe de couche '" +
                      oLayer.get("key_layer") +
                      "' dans les tables."
                  );
              }
            } else {
              console.log(
                "Erreur : Le groupe de couches '" +
                  oLayer.get("key_layer") +
                  "' n'a pas de couches associées dans les tables."
              );
            }
          }
          this.map.addLayer(oLayer);
        } else {
          console.log(
            "Erreur : impossible de trouver la couche JS nommée '" +
              oLayer.get("key_layer") +
              "' dans les tables."
          );
        }
      });

    this.actualizeZIndex();
    this.opacityLayers.reverse();
    this.updateLayerSite();
    this.updateLayerSiteUf();
    this.updateLayerSiteParcelle();

    //this.map.updateSize();
    // manage getFeatureInfo on wms
    this.map.on("singleclick", evt => {
      //this.closePopup();
      const oLayerSearchable = this.getDefaultSearchableLayer();
      let oLayerConfig = this.getLayerConfigByKey(
        oLayerSearchable.get("key_layer")
      );
      // get config if layer is a group
      if (typeof oLayerConfig.Layers !== "undefined") {
        for (let oLayerFromGroup of oLayerConfig.Layers) {
          if (oLayerFromGroup.key_layer == oLayerSearchable.get("key_layer"))
            oLayerConfig = oLayerFromGroup;
        }
      }
      this.featurePopup.layer = oLayerSearchable.get("name");
      if (
        oLayerSearchable.getSource() instanceof source.TileWMS ||
        oLayerSearchable.getSource() instanceof source.ImageWMS
      ) {
        const url = oLayerSearchable
          .getSource()
          .getFeatureInfoUrl(
            evt.coordinate,
            this.map.getView().getResolution(),
            this.map.getView().getProjection(),
            { INFO_FORMAT: "application/vnd.ogc.gml" }
          );
        axios.get(url).then(response => {
          const parser = new format.WMSGetFeatureInfo();
          let aFeatures = parser.readFeatures(response.data);
          if (aFeatures.length > 0) {
            this.featurePopup.features = [];
            this.setFeaturePopup(aFeatures[0], oLayerConfig);
            this.clickCoordinate = transform(
              evt.coordinate,
              this.mapProjection,
              "EPSG:4326"
            );
            this.openPopup();
          }
        });
      } else if (oLayerSearchable.getSource() instanceof source.Vector) {
        /* Doesn't work with layer geometry with point object
          const aFeatures = oLayerSearchable
            .getSource().getFeaturesAtCoordinate(evt.coordinate);*/
        let aFeatures = [];
        this.map.forEachFeatureAtPixel(
          evt.pixel,
          function(feature) {
            if (typeof feature !== "undefined") aFeatures.push(feature);
          },
          {
            layerFilter: function(layer) {
              return (
                layer.get("key_layer") === oLayerSearchable.get("key_layer")
              );
            }
          }
        );
        if (aFeatures.length > 0) {
          this.featurePopup.features = [];
          this.setFeaturePopup(aFeatures[0], oLayerConfig);
          this.clickCoordinate = transform(
            evt.coordinate,
            this.mapProjection,
            "EPSG:4326"
          );
          this.openPopup();
        }
      }
    });

    this.map.getViewport().addEventListener("contextmenu", evt => {
      evt.preventDefault();
      this.featuresPopupList.features = [];
      this.featuresPopupList.title = "Aucun objet trouvé";
      let nbFeatures = 0;
      const aPixel = [evt.layerX, evt.layerY];
      //https://stackoverflow.com/questions/55059864/how-to-remove-feature-on-right-click-in-openlayers-5
      const aCoordinate = this.map.getCoordinateFromPixel(aPixel);
      this.openPopupList(aCoordinate);
      this.clickPixel = aPixel;
      this.clickCoordinate = transform(
        aCoordinate,
        this.mapProjection,
        "EPSG:4326"
      );
      this.mapConfig.Layers.forEach(oLayerConfig => {
        if (oLayerConfig.searchable == true) {
          let oLayerSearchable = this.getLayerByKeyLayer(
            oLayerConfig.key_layer
          );
          let oLayerSource = oLayerSearchable.getSource();
          if (
            oLayerSource instanceof source.TileWMS ||
            oLayerSource instanceof source.ImageWMS
          ) {
            let url = oLayerSource.getFeatureInfoUrl(
              aCoordinate,
              this.map.getView().getResolution(),
              this.map.getView().getProjection(),
              { INFO_FORMAT: "application/vnd.ogc.gml" }
            );
            axios.get(url).then(response => {
              const parser = new format.WMSGetFeatureInfo();
              nbFeatures =
                nbFeatures +
                this.setFeaturesPopupList(
                  parser.readFeatures(response.data),
                  oLayerConfig
                );
              this.featuresPopupList.title = nbFeatures + " objet(s) trouvé(s)";
            });
          } else if (oLayerSource instanceof source.Vector) {
            //const data = oLayerSource.getFeaturesAtCoordinate(aCoordinate);
            let aFeatures = [];
            this.map.forEachFeatureAtPixel(
              aPixel,
              function(feature) {
                if (typeof feature !== "undefined")
                  return aFeatures.push(feature);
              },
              {
                layerFilter: function(layer) {
                  return layer.get("key_layer") === oLayerConfig.key_layer;
                }
              }
            );
            nbFeatures =
              nbFeatures + this.setFeaturesPopupList(aFeatures, oLayerConfig);
          }
          if (nbFeatures > 0) {
            this.featuresPopupList.title = nbFeatures + " objet(s) trouvé(s)";
          }

          /*this.$nextTick(() => {
            this.map.updateSize();
            });*/
        }
      });
      this.mapConfig.LayerGroups.forEach(oLayerConfig => {
        //console.log(oLayerConfig.key_layer);
        if (oLayerConfig.searchable == true) {
          let oLayerSearchable = this.getLayerByKeyLayer(
            oLayerConfig.key_layer
          );
          let oLayerSource = oLayerSearchable.getSource();
          // get config if layer is a group
          if (typeof oLayerConfig.Layers !== "undefined") {
            for (let oLayerFromGroup of oLayerConfig.Layers) {
              if (oLayerFromGroup.key_layer == oLayerConfig.key_layer)
                oLayerConfig = oLayerFromGroup;
            }
          }
          if (
            oLayerSource instanceof source.TileWMS ||
            oLayerSource instanceof source.ImageWMS
          ) {
            let url = oLayerSource.getFeatureInfoUrl(
              aCoordinate,
              this.map.getView().getResolution(),
              this.map.getView().getProjection(),
              { INFO_FORMAT: "application/vnd.ogc.gml" }
            );
            axios.get(url).then(response => {
              const parser = new format.WMSGetFeatureInfo();
              nbFeatures =
                nbFeatures +
                this.setFeaturesPopupList(
                  parser.readFeatures(response.data),
                  oLayerConfig
                );
              this.featuresPopupList.title = nbFeatures + " objet(s) trouvé(s)";
            });
          } else if (oLayerSource instanceof source.Vector) {
            //const data = oLayerSource.getFeaturesAtCoordinate(aCoordinate);
            let aFeatures = [];
            this.map.forEachFeatureAtPixel(
              aPixel,
              function(feature) {
                if (typeof feature !== "undefined")
                  return aFeatures.push(feature);
              },
              {
                layerFilter: function(layer) {
                  return layer.get("key_layer") === oLayerConfig.key_layer;
                }
              }
            );
            nbFeatures =
              nbFeatures + this.setFeaturesPopupList(aFeatures, oLayerConfig);
          }
          if (nbFeatures > 0) {
            this.featuresPopupList.title = nbFeatures + " objet(s) trouvé(s)";
          }
        }
      });
    });
  },
  beforeDestroy() {
    this.$forceUpdate();
    this.map.updateSize();
  }
};
</script>
