<template>
  <v-card
    v-if="display"
    min-width="250"
    class="mx-left card_map_tool"
    elevation="10"
  >
    <v-card-title>
      {{ $t("mapTool.title") }}
      <v-btn icon absolute right small @click="closeTool()"
        ><v-icon size="15">mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text class="ma-1">
      <v-row align="center">
        <v-col cols="3" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                color="secondary"
                dark
                @click="measureLineStringTool()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-vector-line</v-icon>
              </v-btn>
            </template>
            <span>Mesurer une distance</span>
          </v-tooltip>
        </v-col>
        <v-col cols="3" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                color="secondary"
                dark
                @click="measurePolygonTool()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-vector-polygon</v-icon>
              </v-btn>
            </template>
            <span>Mesurer une surface</span>
          </v-tooltip>
        </v-col>
        <v-col cols="3" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                retain-focus-on-click
                small
                fab
                color="secondary"
                dark
                @click="cleanToolMeasureLayer()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-eraser</v-icon>
              </v-btn>
            </template>
            <span>Effacer les mesures</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style lang="css" scoped>
.card_map_tool {
  z-index: 10;
  width: max-content;
  height: max-content;
  position: absolute;
  top: 1px;
  left: 57px;
}
</style>
<script>
export default {
  data() {
    return {
      toolMeasureLineString: false,
      toolMeasurePolygon: false
    };
  },
  props: {
    display: Boolean
  },
  methods: {
    closeTool: function() {
      this.$emit("closeTool");
    },
    measureLineStringTool: function() {
      if (!this.toolMeasureLineString) {
        if (this.toolMeasurePolygon) {
          this.$emit("removeToolMeasure");
        }
        this.toolMeasureLineString = true;
        this.$emit("initToolMeasure", "LineString");
      } else {
        this.toolMeasureLineString = false;
        this.$emit("removeToolMeasure");
      }
    },
    measurePolygonTool: function() {
      if (!this.toolMeasurePolygon) {
        if (this.toolMeasureLineString) {
          this.$emit("removeToolMeasure");
        }
        this.toolMeasurePolygon = true;
        this.$emit("initToolMeasure", "Polygon");
      } else {
        this.toolMeasurePolygon = false;
        this.$emit("removeToolMeasure");
      }
    },
    cleanToolMeasureLayer: function() {
      this.$emit("cleanToolMeasureLayer");
    }
  }
};
</script>
