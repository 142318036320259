<template>
  <v-card>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="cancelEditing()">
        {{ $t("buttons.close") }}
      </v-btn>
    </v-card-actions>
    <v-card-title class="justify-center">
      <span class="headline">{{ formTitleMap }}</span>
    </v-card-title>
    <v-form v-model="validForm" ref="map_form">
      <v-container>
        <v-row>
          <v-col cols="7">
            <v-row align="center" justify="center">
              <v-col cols="8">
                <v-text-field
                  v-model="editedMap.name"
                  :rules="[rules.required, rules.max(150)]"
                  :label="$t('fields.name') + ' *'"
                  validate-on-blur
                  @keyup.enter="$refs.edit_x.focus()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <span>Centre:</span>
              <v-col cols="3">
                <v-text-field
                  v-model.number="editedMap.default_center_x"
                  :rules="[rules.number]"
                  label="X *"
                  type="number"
                  validate-on-blur
                  ref="edit_x"
                  @keyup.enter="$refs.edit_y.focus()"
                  @change="updateMapDisplay()"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="editedMap.default_center_y"
                  label="Y *"
                  type="number"
                  :rules="[rules.number]"
                  validate-on-blur
                  ref="edit_y"
                  @keyup.enter="$refs.edit_z.focus()"
                  @change="updateMapDisplay()"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="editedMap.default_zoom"
                  label="Zoom *"
                  type="number"
                  :rules="[rules.number]"
                  validate-on-blur
                  ref="edit_z"
                  @keyup.enter="$refs.edit_min_x.focus()"
                  @change="updateMapDisplay()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <span>Etendu:</span>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="3">
                <v-text-field
                  v-model.number="editedMap.default_extent[0]"
                  label="min X *"
                  :rules="[rules.required]"
                  validate-on-blur
                  type="number"
                  ref="edit_min_x"
                  @keyup.enter="$refs.edit_max_x.focus()"
                  @change="updateMapDisplay()"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="editedMap.default_extent[1]"
                  label="max X *"
                  type="number"
                  :rules="[rules.required]"
                  validate-on-blur
                  ref="edit_max_x"
                  @keyup.enter="$refs.edit_min_y.focus()"
                  @change="updateMapDisplay()"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="editedMap.default_extent[2]"
                  label="min Y *"
                  type="number"
                  :rules="[rules.required]"
                  validate-on-blur
                  ref="edit_min_y"
                  @keyup.enter="$refs.edit_max_y.focus()"
                  @change="updateMapDisplay()"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="editedMap.default_extent[3]"
                  label="max Y *"
                  type="number"
                  :rules="[rules.required]"
                  validate-on-blur
                  ref="edit_max_y"
                  @keyup.enter="saveMap()"
                  @change="updateMapDisplay()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <SimpleMap
              ref="carte"
              :carte="editedMap"
              @setUpdatedMap="setUpdatedMap"
            ></SimpleMap>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <small>* {{ $t("fields.required") }}</small>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="saveMap"
        :disabled="!saveBtnEnabled || !validForm"
        data-name="saveMap"
      >
        {{ $t("buttons.save") }}
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-col cols="12">
      <v-data-table
        :headers="headersLayer"
        :items="editedMap.Layers"
        hide-default-footer
        no-data-text="Aucune couche associé"
        disable-pagination
        class="elevation-1"
        ><template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>
              {{ $t("admin.layerList") }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-select
              v-if="mapCreated"
              v-model="layerToAdd"
              :items="layersCanbeAdd"
              @change="addLayer(layerToAdd)"
              item-value="name"
              item-text="name"
              label="Selectionné couche à ajouter"
              return-object
              no-data-text="Toute les couches sont déjà associé"
              dense
              single-line
              fill
              ref="selectLayer"
            ></v-select>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="goToUpdateLayer(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteLayer(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headersGroup"
        :items="editedMap.LayerGroups"
        hide-default-footer
        no-data-text="Aucun groupe associé"
        disable-pagination
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>
              {{ $t("admin.groupLayerList") }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-select
              v-if="mapCreated"
              v-model="groupToAdd"
              :items="groupsCanBeAdd"
              @change="addLayerGroup(groupToAdd)"
              item-value="key_layer"
              :item-text="textSelectGroup"
              disa
              label="Selectionné groupe à ajouter"
              return-object
              no-data-text="Tout les groupes sont déjà associé"
              dense
              single-line
              ref="selectLayerGroup"
              fill
            ></v-select>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="goToUpdateGroupLayer(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteLayerGroup(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>
<script>
import SimpleMap from "./SimpleMap";
import GroupServices from "../../../services/layer_group";
import MapsServices from "../../../services/map";
import MapLayerServices from "../../../services/map_layer";

export default {
  components: {
    SimpleMap
  },
  data() {
    return {
      map: null,

      groupToAdd: [],
      groupsCanBeAdd: [],

      layerToAdd: [],
      layersCanbeAdd: [],

      mapCreated: false,
      editedMap: {
        name: null,
        default_center_x: null,
        default_center_y: null,
        default_zoom: null,
        default_extent: [null, null, null, null],
        Layers: [],
        LayerGroups: []
      },

      saveBtnEnabled: true,
      validForm: false,
      rules: {
        required: v => !!v || this.$t("fields.rules.required"),
        number: v =>
          Number.isInteger(v) || !v || this.$t("fields.rules.number"),
        max: x => v =>
          !v || (v && v.length <= x) || this.$t("fields.rules.max", { c: x })
      },
      headersGroup: [
        {
          text: "Nom",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Clé de la couche", value: "key_layer" },
        { text: "Thème", value: "theme_id" },
        { text: "Cherchable", value: "searchable" },
        { text: "Visible", value: "visible" },
        { text: "Zoom minimum", value: "min_zoom" },
        { text: "Zoom maximum", value: "max_zoom" },
        { text: "Opacité", value: "opacity" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      headersLayer: [
        {
          text: "Nom",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Clé de la couche", value: "key_layer" },
        { text: "Thème", value: "theme_id" },
        { text: "Visible", value: "visible" },
        { text: "Zoom minimum", value: "min_zoom" },
        { text: "Zoom maximum", value: "max_zoom" },
        { text: "Opacité", value: "opacity" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  props: {
    addOrUpdating: Number,
    mapToEdit: Object,
    layersList: Array,
    groupsList: Array,
    tab: Number
  },
  computed: {
    formTitleMap() {
      this.$nextTick(() => {
        this.$refs.carte.updateValue();
      });
      return this.mapToEdit.name
        ? "Modification de la carte"
        : "Nouvelle carte";
    }
  },
  watch: {
    $mapToEdit: {
      handler() {
        if (this.addOrUpdating == 2) {
          this.mapCreated = true;
          this.editedMap = Object.assign({}, this.mapToEdit);
          this.layersCanbeAdd = this.layersList.filter(
            ({ key_layer: id1 }) =>
              !this.editedMap.Layers.some(({ key_layer: id2 }) => id2 === id1)
          );
          this.groupsCanBeAdd = this.groupsList.filter(
            ({ key_layer: id1 }) =>
              !this.editedMap.LayerGroups.some(
                ({ key_layer: id2 }) => id2 === id1
              )
          );
        }
        if (this.addOrUpdating == 1) {
          this.mapCreated = false;
          this.layersCanbeAdd = this.layersList;
          this.groupsCanBeAdd = this.groupsList;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    textSelectGroup: item => item.name + " (" + item.key_layer + ")",
    cancelEditing() {
      this.$emit("closeEditingPage");
    },
    goToUpdateGroupLayer(item) {
      this.$emit("closeEditingPage");
      this.$emit("toUpdateLayer", 2, item);
    },
    goToUpdateLayer(item) {
      this.$emit("closeEditingPage");
      this.$emit("toUpdateLayer", 1, item);
    },
    async saveMap() {
      let formIsValid = this.$refs.map_form.validate();
      if (!formIsValid || !this.saveBtnEnabled) return;

      this.saveBtnEnabled = false;
      if (this.addOrUpdating == 1)
        try {
          let res = await MapsServices.postMap(this.editedMap);
          this.editedMap = {
            ...res.data,
            Layers: [],
            LayerGroups: []
          };
          this.$toast.success(this.$t("toaster.mapCreated"));
          this.$emit("fillArray");
          this.mapCreated = true;
        } catch (e) {
          this.$toast.error(this.$t("toaster.error") + e);
          this.saveBtnEnabled = true;
        }
      if (this.addOrUpdating == 2)
        try {
          await MapsServices.putMap(this.editedMap);
          this.$toast.success(this.$t("toaster.mapUpdated"));
          this.$emit("fillArray");
        } catch (e) {
          this.$toast.error(this.$t("toaster.error") + e);
          this.saveBtnEnabled = true;
        }
    },
    async setUpdatedMap(newSet) {
      this.editedMap.default_zoom = newSet.zoom;
      this.editedMap.default_center_x = newSet.center_x;
      this.editedMap.default_center_y = newSet.center_y;
      this.editedMap.default_extent = newSet.extent;
      await this.$nextTick();
      this.$refs.map_form.validate();
      this.$refs.map_form.resetValidation();
    },
    addLayerGroup(item) {
      this.$refs.selectLayerGroup.blur();
      this.editedMap.LayerGroups.push(item);
      this.groupsCanBeAdd = this.groupsCanBeAdd.filter(el => el !== item);
      GroupServices.postMapLayerGroup({
        map_id: this.editedMap.id,
        layer_group_id: item.id
      })
        .then(res => this.$toast.success(res.data.message))
        .catch(error => {
          this.$toast.error(this.$t("toaster.error"));
          console.log(error);
        });
    },
    addLayer(item) {
      this.$refs.selectLayer.blur();
      this.editedMap.Layers.push(item);

      this.layersCanbeAdd = this.layersCanbeAdd.filter(el => el !== item);
      MapLayerServices.postMapLayer({
        map_id: this.editedMap.id,
        layer_id: item.id
      })
        .then(res => this.$toast.success(res.data.message))
        .catch(error => {
          this.$toast.error(this.$t("toaster.error"));
          console.log(error);
        });
    },
    deleteLayerGroup(item) {
      this.groupsCanBeAdd.push(item);
      this.groupToAdd = null;
      this.editedMap.LayerGroups = this.editedMap.LayerGroups.filter(
        el => el !== item
      );
      GroupServices.deleteMapLayerGroup({
        map_id: this.editedMap.id,
        layer_group_id: item.id
      })
        .then(res => this.$toast.success(res.data.message))
        .catch(error => {
          this.$toast.error(this.$t("toaster.error"));
          console.log(error);
        });
    },
    deleteLayer(item) {
      this.layersCanbeAdd.push(item);
      this.layerToAdd = null;
      this.editedMap.Layers = this.editedMap.Layers.filter(el => el !== item);
      MapLayerServices.deleteMapLayer({
        map_id: this.editedMap.id,
        layer_id: item.id
      })
        .then(res => this.$toast.success(res.data.message))
        .catch(error => {
          this.$toast.error(this.$t("toaster.error"));
          console.log(error);
        });
    },
    handleKeydown(e) {
      if (e.key === "Escape") {
        this.cancelEditing();
      }
    },
    updateMapDisplay() {
      this.$refs.carte.updateValue();
    }
  },
  created() {
    window.addEventListener("keyup", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeydown);
  }
};
</script>
