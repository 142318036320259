import * as layer from "ol/layer";
import * as source from "ol/source";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import WMTS, { optionsFromCapabilities } from "ol/source/WMTS";
import WMTSCapabilities from "ol/format/WMTSCapabilities";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { get as getProjection } from "ol/proj";
proj4.defs(
  "EPSG:2154",
  "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

const aResolutions = [
  156543.03392804103,
  78271.5169640205,
  39135.75848201024,
  19567.879241005125,
  9783.939620502562,
  4891.969810251281,
  2445.9849051256406,
  1222.9924525628203,
  611.4962262814101,
  305.74811314070485,
  152.87405657035254,
  76.43702828517625,
  38.218514142588134,
  19.109257071294063,
  9.554628535647034,
  4.777314267823517,
  2.3886571339117584,
  1.1943285669558792,
  0.5971642834779396,
  0.29858214173896974,
  0.14929107086948493,
  0.07464553543474241
];

export const backgroundMapList = [
  new layer.Tile({
    name: "Orthophotos IGN",
    visible: false,
    avatar: "../layers/ortho_ign.png",
    crossOrigin: "anonymous",
    source: new source.WMTS({
      attributions: ["IGN-F/Géoportail"],
      url: "https://wxs.ign.fr/choisirgeoportail/geoportail/wmts",
      layer: "ORTHOIMAGERY.ORTHOPHOTOS",
      matrixSet: "PM",
      format: "image/jpeg",
      style: "normal",
      crossOrigin: "anonymous",
      projection: getProjection("EPSG:3857"),
      tileGrid: new WMTSTileGrid({
        origin: [-20037508, 20037508],
        resolutions: aResolutions,
        matrixIds: [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19"
        ]
      })
    })
  }),
  new layer.Tile({
    name: "OpenStreetMap Fr",
    visible: false,
    avatar: "../layers/osm.png",
    crossOrigin: "anonymous",
    source: new source.XYZ({
      attributions: ["OpenStreetMap France"],
      crossOrigin: "anonymous",
      url: "https://{a-c}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
    })
  }),
  new layer.Tile({
    name: "OpenStreetMap Positron",
    visible: true,
    avatar: "../layers/osm_nb.png",
    crossOrigin: "anonymous",
    source: new source.XYZ({
      attributions: ["OpenStreetMap - CartoDB"],
      url:
        "https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
    })
  })
];

const parser = new WMTSCapabilities();

export const LayerOrtho93 = new layer.Tile({
  name: "Orthophotos IGN",
  visible: false,
  avatar: "../layers/ortho_ign.png"
});
fetch(
  "https://wxs.ign.fr/lambert93/geoportail/wmts?SERVICE=WMTS&request=GetCapabilities"
)
  .then(function(response) {
    return response.text();
  })
  .then(function(text) {
    const result = parser.read(text);
    const options = optionsFromCapabilities(result, {
      layer: "ORTHOIMAGERY.ORTHOPHOTOS.BDORTHO.L93",
      matrixSet: "LAMB93"
    });
    options.crossOrigin = "anonymous";
    options.projection = "EPSG:2154";
    LayerOrtho93.setSource(new WMTS(options));
  });

export const OpenStreetMap_FR = new layer.Tile({
  name: "OpenStreetMap Fr",
  visible: false,
  avatar: "../layers/osm.png",
  crossOrigin: "anonymous",
  source: new source.XYZ({
    attributions: ["OpenStreetMap France"],
    crossOrigin: "anonymous",
    url: "https://{a-c}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
  })
});

export const OpenStreetMap = new layer.Tile({
  name: "OpenStreetMap Positron",
  visible: true,
  avatar: "../layers/osm_nb.png",
  crossOrigin: "anonymous",
  source: new source.XYZ({
    attributions: ["OpenStreetMap - CartoDB"],
    url:
      "https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
  })
});
