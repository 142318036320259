import Vue from "vue";
import Vuex from "vuex";
import "../plugins/toaster";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    tiers: null,
    selectedDep: null,
    user: null,
    map: null,
    zoomMap: {
      id: [],
      layer: null,
      attribute_id: null
    }
  },
  mutations: {
    setZoomMap(state, data) {
      state.zoomMap.id = data.zoomMapId;
      state.zoomMap.layer = data.zoomMapLayer;
      state.zoomMap.attribute_id = data.attribute_id;
    },
    setMap(state, data) {
      state.map = data;
      localStorage.setItem("map", data);
    },
    setUser(state, data) {
      state.user = data;
    },
    setTiers(state, data) {
      state.tiers = data;
    },
    setSelectedDep(state, data) {
      state.selectedDep = data;
    }
  },
  getters: {
    zoomMap: state => {
      return state.zoomMap;
    },
    map: state => {
      return state.map;
    },
    selectedDep: state => {
      return state.selectedDep;
    },
    user: state => {
      return state.user;
    }
  },
  actions: {},
  modules: {}
});

export default store;
