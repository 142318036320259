<template>
  <v-card flat>
    <v-tabs v-model="tab" background-color="secondary" dark grow>
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <v-card v-if="item == 'Cartes'" flat>
          <MapList @toUpdateLayer="toUpdateLayer" />
        </v-card>
        <v-card v-if="item == 'Groupes de couche'" flat>
          <LayerGroupList ref="groupLayer" />
        </v-card>
        <v-card v-if="item == 'Couches'" flat>
          <LayerList ref="layer" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import MapList from "./map/MapList";
import LayerList from "./layer/LayerList";
import LayerGroupList from "./layer_group/LayerGroupList";

export default {
  components: { MapList, LayerGroupList, LayerList },
  data() {
    return {
      tab: null,
      items: ["Cartes", "Couches", "Groupes de couche"]
    };
  },
  methods: {
    async toUpdateLayer(id, layer) {
      this.tab = id;
      await this.$nextTick();
      await this.$nextTick();
      if (id == 1) this.$refs.layer[0].setLayerToEdit(layer);
      if (id == 2) this.$refs.groupLayer[0].setLayerGroupToEdit(layer);
    }
  },
  created() {
    if (!this.$store.state.auth.user.roles.includes("SUPERADMIN")) {
      this.$router.push("/");
      this.$toast.error("Vous n'avez pas les droits pour accéder à cette page");
    }
  }
};
</script>
