<template>
  <div id="mly" clas="ml-auto mr-auto" style="width: 490px; height: 320px;">
    <div class="App" />
  </div>
</template>
<style scoped>
@import url("https://unpkg.com/mapillary-js@2.18.0/dist/mapillary.min.css");
</style>
<script>
import * as Mapillary from "mapillary-js";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      mly: null
    };
  },
  props: {
    coordinate: Array,
    active: Boolean
  },
  async mounted() {
    this.mly = new Mapillary.Viewer(
      {
        container: "mly",
        apiClient: "SjNwNm9WVEU0bnNieUpUbmhJV0VMdjpmNjdiZTg0ZDY5NmRiNTk5"
        // qualifriches@gmail.com
        // Mod@@l69!
      },
      {
        component: {
          cover: false
        }
      }
    );
    this.setPosition();
  },
  methods: {
    setPosition: function() {
      if (this.coordinate) {
        axios
          .get(
            "https://a.mapillary.com/v3/images?client_id=SjNwNm9WVEU0bnNieUpUbmhJV0VMdjpmNjdiZTg0ZDY5NmRiNTk5&closeto=" +
              this.coordinate[0] +
              "," +
              this.coordinate[1] +
              "&radius=100"
          )
          .then(response => {
            if (response.data.features.length != 0) {
              if (this.mly.isNavigable)
                this.mly.moveToKey(response.data.features[0].properties.key);
            } else {
              this.$toast.error(
                "Mapillary : aucune image disponible pour ce site"
              );
            }
          });
      } else {
        this.$toast.error(
          "Mapillary : pas de coordonnées pour trouver une image pour ce site"
        );
      }
    }
  },
  watch: {
    coordinate: function() {
      this.setPosition();
    },
    active: function() {
      if (this.active) {
        document.getElementById("mly").style.height = "0px";
      } else document.getElementById("mly").style.height = "300px";
    }
  }
};
</script>
