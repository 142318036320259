<template>
  <v-card dark flat height="50px" elevation="10" class="select-location">
    <v-toolbar floating color="secondary" height="50px" width="100%">
      <v-autocomplete
        v-model="address_selected"
        :items="entries"
        :loading="isLoading"
        :search-input.sync="search"
        item-text="text"
        item-value="value"
        no-filter
        clearable
        label="Localiser une adresse"
        no-data-text="Pas de données trouvées"
        :placeholder="$t('selectLocation.selectPlaceholder')"
        prepend-icon="mdi-map-search-outline"
        return-object
        @change="zoomToSelected()"
      ></v-autocomplete>
      <!--<v-btn icon>
        <v-icon>my_location</v-icon>
      </v-btn>-->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item dense v-for="(item, i) in items" :key="i">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </v-card>
</template>
<style lang="css" scoped>
.select-location {
  z-index: 7;
  width: 320px;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
}
.v-toolbar__content .v-btn.v-btn--icon.v-size--default {
  height: 30px;
  width: 30px;
}
.v-toolbar__content .v-input {
  font-size: 14px;
  padding-top: 30px;
}
</style>
<style lang="css">
.v-toolbar__content {
  width: 100%;
}
</style>
<script>
export default {
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    address_selected: null,
    search: null,
    items: [{ title: "Adresses" }]
    //items: [{ title: "Adresses" }, { title: "Friches" }, { title: "Projets" }]
  }),
  computed: {},
  methods: {
    zoomToSelected: function() {
      if (this.address_selected) {
        const coord = this.address_selected.value.split("|");
        this.$emit("zoomToSelected", coord[0], coord[1]);
      }
    }
  },

  watch: {
    search(value) {
      if (typeof value === "undefined") return;
      if (!value || value.length <= 3) return;

      const searchString = value.replace(/ /g, "+").toLowerCase();

      this.isLoading = true;
      // Lazily load input items
      //fetch('https://api.publicapis.org/entries')
      fetch("https://api-adresse.data.gouv.fr/search/?q=" + searchString)
        .then(res => res.json())
        .then(res => {
          let entries = [];
          if (res.features.length > 0) {
            res.features.forEach(feature =>
              entries.push({
                value:
                  feature.geometry.coordinates[0] +
                  "|" +
                  feature.geometry.coordinates[1],
                text: feature.properties.label
              })
            );
          }
          if (entries.length > 0) this.entries = entries;
        })
        .catch(err => {
          this.$toast.error(err);
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
