<template>
  <v-container>
    <v-data-table
      v-if="addOrUpdating == 0"
      :headers="headersLayer"
      :items="srcLayers"
      hide-default-footer
      disable-pagination
      :loading="loading"
      :loading-text="$t('admin.loading')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{ $t("admin.layerList") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="addOrUpdating = 1">
            {{ $t("admin.newLayer") }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.theme_id`]="{ item }">
        {{ getThemeName(item.id) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="setLayerToEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteLayer(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <template v-if="addOrUpdating != 0">
      <UpdateLayer
        :layerToEdit="layerToEdit"
        :addOrUpdating="addOrUpdating"
        @closeEditingPage="closeEditingPage"
        @fillArray="fillArray"
      ></UpdateLayer>
    </template>
  </v-container>
</template>
<script>
import UpdateLayer from "./UpdateLayer";
import LayersServices from "../../../services/layer";
import ThemeServices from "../../../services/theme";

export default {
  components: {
    UpdateLayer
  },
  data() {
    return {
      themeList: [],
      addOrUpdating: 0,
      loading: true,
      srcLayers: [],
      layerToEdit: {
        name: null,
        key_layer: null,
        max_zoom: null,
        min_zoom: null,
        opacity: null,
        visible: null,
        theme_id: null
      },
      defaultLayer: {
        name: null,
        key_layer: null,
        max_zoom: null,
        min_zoom: null,
        opacity: null,
        visible: null,
        theme_id: null
      },
      headersLayer: [
        {
          text: "Nom",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Clé de la couche", value: "key_layer" },
        { text: "Thème", value: "theme_id" },
        { text: "Visible", value: "visible" },
        { text: "Zoom minimum", value: "min_zoom" },
        { text: "Zoom maximum", value: "max_zoom" },
        { text: "Opacité", value: "opacity" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    async fillArray() {
      try {
        let layers = await LayersServices.getLayerList();
        this.srcLayers = layers.data;
        this.loading = false;
        let themes = await ThemeServices.getThemeList();
        this.themeList = themes.data;
      } catch (e) {
        this.$toast.error(this.$t("toaster.error") + e);
      }
    },
    getThemeName(id) {
      let theme = this.themeList.filter(e => e.id == id)[0];
      if (theme) return theme.name;
    },
    setLayerToEdit(item) {
      this.addOrUpdating = 2;
      this.layerToEdit = JSON.parse(JSON.stringify(item));
    },
    closeEditingPage() {
      this.layerToEdit = Object.assign({}, this.defaultLayer);
      this.addOrUpdating = 0;
    },
    async deleteLayer(item) {
      confirm(
        'Êtes vous sûr de vouloir supprimer la couche "' + item.name + '" ?'
      ) &&
        LayersServices.deleteLayer(item.id)
          .then(() => {
            this.fillArray();
            this.$toast.success("Couche supprimé");
          })
          .catch(err => {
            this.$toast.error(err);
            console.log(err);
          });
    }
  },
  async created() {
    this.fillArray();
  }
};
</script>
