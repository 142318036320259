/**
 * Escape unsafe HTML
 * @param {string} unsafe Unsafe HTML
 * @returns Escaped HTML string
 * @author https://stackoverflow.com/a/6234804/4726671
 */
export const escapeHTML = unsafe => {
  return unsafe
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll('"', "&quot;")
    .replaceAll("'", "&#039;");
};

/**
 * Convert a string date to local format
 * @param {string} dateToConvert
 * @returns String date local format
 */
export const dateConvertLocal = dateToConvert => {
  let oDate = new Date(dateToConvert);
  return oDate.toLocaleString();
};

/**
 * Parse date to db format
 * @param {String} date - dd/mm/yyyy date string
 * @returns yyyy-mm-dd date string
 */
export const parseDate = date => {
  const [d, m, y] = date.split("/");
  return `${y}-${m.padStart(2, "0")}-${d.padStart(2, "0")}`;
};

/**
 * Format db date to french date format
 * @param {String} date - yyyy-mm-dd date string
 * @returns dd/mm/yyyy formatted date string
 */
export const formatDate = date => {
  date = date.split("T")[0];
  const [y, m, d] = date.split("-");
  return `${d.padStart(2, "0")}/${m.padStart(2, "0")}/${y}`;
};

export const timeSince = date => {
  const sinceDate = new Date(date);
  const seconds = Math.floor((new Date() - sinceDate) / 1000);
  const years = Math.round((seconds / 31536000) * 2) / 2;
  const months = Math.round((seconds / 2592000) * 2) / 2;
  const days = Math.round((seconds / 86400) * 2) / 2;

  const num2Str = n => Math.round(n * 10) / 10;

  let since = "";
  if (years >= 1) {
    since += `${num2Str(years)} an`;
    if (years > 1.5) since += "s";
  } else if (months >= 1) {
    since += `${num2Str(months)} mois`;
  } else if (days >= 1) {
    since += `${num2Str(days)} jour`;
    if (days > 1.5) since += "s";
  }
  return since;
};

/**
 * Deeply nullify empty strings (or any other value) in object
 * Changes are applied to original object
 * @param o - Object
 * @param v value(s) to nullify
 */
export const deepNullify = (o, v = "") => {
  if (!Array.isArray(v)) v = [v];
  for (const k in o) {
    if (v.includes(o[k])) {
      o[k] = null;
    } else if (typeof o[k] === "object") {
      o[k] = deepNullify(o[k]);
    }
  }
  return o;
};

export const cloneObject = object => JSON.parse(JSON.stringify(object));
