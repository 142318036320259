<template>
  <div id="map" class="map">
    <v-fab-transition>
      <v-btn color="primary" dark x-small bottom left @click="setUpdatedMap()">
        <v-icon style="font-size: 12px">Utiliser cette carte</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<style scoped>
.map {
  height: 300px;
}
</style>
<script>
import OSM from "ol/source/OSM";

export default {
  data() {
    return {
      map: null,
      center: [0, 0],
      zoom: 1
    };
  },
  props: {
    carte: Object
  },
  methods: {
    initMap() {
      this.map = new this.Map({
        layers: [
          new this.layer.Tile({
            source: new OSM()
          })
        ],
        target: "map",
        view: new this.View({
          center: this.center,
          zoom: this.zoom
        })
      });
    },
    updateValue() {
      this.center[0] = this.carte.default_center_x;
      this.center[1] = this.carte.default_center_y;
      this.zoom = this.carte.default_zoom;
      if (this.map == null) this.initMap();
      else {
        this.map.getView().setCenter(this.center);
        this.map.getView().setZoom(this.zoom);
      }
      this.$nextTick(() => {
        this.map.updateSize();
      });
    },
    setUpdatedMap: function() {
      if (this.map == null) this.initMap();
      let center = this.map.getView().getCenter();
      let extent = this.map.getView().calculateExtent(this.map.getSize());
      this.$emit("setUpdatedMap", {
        zoom: parseInt(this.map.getView().getZoom()),
        center_x: parseInt(center[0]),
        center_y: parseInt(center[1]),
        extent: [
          parseInt(extent[0]),
          parseInt(extent[1]),
          parseInt(extent[2]),
          parseInt(extent[3])
        ]
      });
    }
  }
};
</script>
