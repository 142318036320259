import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as VeeValidate from "vee-validate";
import "./plugins/toaster";
import vuetify from "./plugins/vuetify";
import { i18n } from "./plugins/i18n";
// Map
import Map from "ol/Map";
import View from "ol/View";
import * as layer from "ol/layer";
Vue.prototype.Map = Map;
Vue.prototype.View = View;
Vue.prototype.layer = layer;
import "ol/ol.css";

Vue.config.productionTip = false;
Vue.use(VeeValidate);

export const VueInstance = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
