import WMTSTileGrid from "ol/tilegrid/WMTS";
import { get as getProjection } from "ol/proj";
import * as layer from "ol/layer";
import * as source from "ol/source";
//import { createEmpty, extend, getHeight, getWidth } from "ol/extent";
//import Collection from "ol/Collection";
import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import { fStylePLU, fStyleSite, fStyleSiteParcelle } from "./layer_style";

const aResolutions = [
  156543.03392804103,
  78271.5169640205,
  39135.75848201024,
  19567.879241005125,
  9783.939620502562,
  4891.969810251281,
  2445.9849051256406,
  1222.9924525628203,
  611.4962262814101,
  305.74811314070485,
  152.87405657035254,
  76.43702828517625,
  38.218514142588134,
  19.109257071294063,
  9.554628535647034,
  4.777314267823517,
  2.3886571339117584,
  1.1943285669558792,
  0.5971642834779396,
  0.29858214173896974,
  0.14929107086948493,
  0.07464553543474241
];
/*const oStyleFriches = new Style({
  image: new Circle({
    fill: new Fill({
      color: "rgba(255, 0, 0)"
    }),
    radius: 5
  }),
  fill: new Fill({
    color: "rgba(255, 0, 0, 0.2)"
  }),
  stroke: new Stroke({
    color: "#FF0000",
    width: 1
  }),
  text: new Text({
    font: "12px Calibri,sans-serif",
    textBaseline: "bottom",
    fill: new Fill({
      color: "#FF0000"
    }),
    stroke: new Stroke({
      color: "#fff",
      width: 3
    })
  })
});

let maxFeatureCount;
export let oLayerSiteCluster = null;
const calculateClusterInfo = function(resolution) {
  maxFeatureCount = 0;
  let features = oLayerSiteCluster.getSource().getFeatures();
  if (features) {
    let feature, radius;
    for (var i = features.length - 1; i >= 0; --i) {
      feature = features[i];
      let originalFeatures = feature.get("features");
      let extent = createEmpty();
      let j = void 0,
        jj = void 0;
      if (originalFeatures) {
        for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
          extend(extent, originalFeatures[j].getGeometry().getExtent());
        }
      }
      maxFeatureCount = Math.max(maxFeatureCount, jj);
      radius = (0.25 * (getWidth(extent) + getHeight(extent))) / resolution;
      feature.set("radius", radius);
    }
  }
};

let currentResolution;
export function styleFricheFunction(feature, resolution) {
  if (resolution != currentResolution) {
    calculateClusterInfo(resolution);
    currentResolution = resolution;
  }
  let style;
  const size = feature.get("features").length;
  style = new Style({
    image: new Circle({
      radius: Math.max(8, size * 4),
      fill: new Fill({
        color: [255, 0, 0, Math.min(0.5, 0.2 + size / maxFeatureCount)]
      })
    }),
    text: new Text({
      text: size.toString(),
      font: "14px Calibri Bold,sans-serif",
      fill: new Fill({
        color: "#fff"
      }),
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.6)",
        width: 3
      })
    })
  });
  return style;
}

oLayerSiteCluster = new layer.Vector({
  key_layer: "sites_en_friche_cluster",
  maxZoom: 13,
  visible: false,
  source: new source.Cluster({
    distance: 40,
    source: new source.Vector({
      features: new Collection(),
      crossOrigin: "Anonymous",
      format: new GeoJSON()
    })
  }),
  style: oStyleFriches
});*/

export const oSourceSite = new source.Vector({
  format: new GeoJSON()
});
export const oLayerSite = new layer.Vector({
  key_layer: "sites_en_friche",
  source: oSourceSite,
  style: fStyleSite
});

export const oSourceSiteUf = new source.Vector({
  format: new GeoJSON()
});
export const oLayerSiteUf = new layer.Vector({
  key_layer: "sites_en_friche_uf",
  source: oSourceSiteUf,
  style: fStyleSiteParcelle
});

export const oSourceSiteParcelle = new source.Vector({
  format: new GeoJSON()
});
export const oLayerSiteParcelle = new layer.Vector({
  key_layer: "sites_en_friche_parcelle",
  source: oSourceSiteParcelle,
  style: fStyleSiteParcelle
});

/*export const oLayerGisementEntreprise = new layer.Tile({
  key_layer: "gisement_entreprise",
  source: new source.TileWMS({
    url:
      process.env.VUE_APP_GEOSERVER_URL +
      "/" +
      process.env.VUE_APP_GEOSERVER_WORKSPACE +
      "/wms?&SERVICE=WMS&VERSION=1.3.0",
    params: {},
    attributions: ["Modaal"],
    ratio: 1,
    serverType: "geoserver",
    crossOrigin: "Anonymous"
  })
});
export const oLayerGisementEntrepriseCluster = new layer.Tile({
  key_layer: "gisement_entreprise_parcelles_cluster",
  maxZoom: 14,
  source: new source.TileWMS({
    url:
      process.env.VUE_APP_GEOSERVER_URL +
      "/" +
      process.env.VUE_APP_GEOSERVER_WORKSPACE +
      "/wms?&SERVICE=WMS&VERSION=1.3.0",
    params: {
      LAYERS:
        process.env.VUE_APP_GEOSERVER_WORKSPACE +
        ":adresse_indicateurs_etab_cluster"
    },
    attributions: ["Modaal"],
    ratio: 1,
    serverType: "geoserver",
    crossOrigin: "Anonymous"
  })
});
export const oLayerGisementEntrepriseParcelle = new layer.Tile({
  key_layer: "gisement_entreprise_parcelles",
  minZoom: 14,
  source: new source.TileWMS({
    url:
      process.env.VUE_APP_GEOSERVER_URL +
      "/" +
      process.env.VUE_APP_GEOSERVER_WORKSPACE +
      "/wms?&SERVICE=WMS&VERSION=1.3.0",
    params: {},
    attributions: ["Modaal"],
    serverType: "geoserver",
    crossOrigin: "Anonymous"
  })
});*/
export const oLayerBasol = new layer.Tile({
  key_layer: "basol",
  source: new source.TileWMS({
    params: {},
    crossOrigin: "Anonymous"
  })
});
export const oLayerBasias = new layer.Tile({
  key_layer: "basias",
  source: new source.TileWMS({
    params: {},
    crossOrigin: "Anonymous"
  })
});
export const oLayerMajicPMO = new layer.Tile({
  key_layer: "majic_pmo",
  minZoom: 14,
  source: new source.TileWMS({
    url:
      "https://geoserver.modaal.fr/geoserver/bdd_referentiel/wms?&SERVICE=WMS&VERSION=1.3.0",
    params: {},
    attributions: ["DGFiP"],
    serverType: "geoserver",
    crossOrigin: "Anonymous"
  })
});
export const oLayerDVFplus = new layer.Tile({
  key_layer: "dvf_plus",
  minZoom: 14,
  source: new source.TileWMS({
    url:
      "https://geoserver.modaal.fr/geoserver/bdd_referentiel/wms?&SERVICE=WMS&VERSION=1.3.0",
    params: {},
    attributions: ["DGFiP"],
    serverType: "geoserver",
    crossOrigin: "Anonymous"
  })
});

export const oLayerGPUZonage = new layer.Vector({
  key_layer: "gpu_zonage",
  source: new source.Vector({
    format: new GeoJSON(),
    url: function(extent) {
      return (
        "https://wxs-gpu.mongeoportail.ign.fr/externe/39wtxmgtn23okfbbs1al2lz3/wfs?service=WFS&" +
        "version=1.1.0&request=GetFeature&typename=wfs_du:zone_urba&" +
        "outputFormat=application/json&srsname=EPSG:2154&bbox=" +
        extent.join(",") +
        ",EPSG:2154"
      );
    },
    strategy: bboxStrategy
  }),
  style: fStylePLU
});
export const oLayerDepartement = new layer.Image({
  key_layer: "departements",
  source: new source.ImageWMS({
    url:
      "https://geoserver.modaal.fr/geoserver/BDD_Référentiel/wms?&SERVICE=WMS&VERSION=1.3.0",
    params: {},
    attributions: ["OpenStreetMap"],
    serverType: "geoserver",
    crossOrigin: "Anonymous"
  })
});
export const oLayerEpci = new layer.Tile({
  key_layer: "epci",
  source: new source.TileWMS({
    url:
      "https://geoserver.modaal.fr/geoserver/BDD_Référentiel/wms?&SERVICE=WMS&VERSION=1.3.0",
    params: {},
    attributions: ["OpenStreetMap"],
    serverType: "geoserver",
    crossOrigin: "Anonymous"
  })
});
export const oLayerCommune = new layer.Image({
  key_layer: "communes",
  source: new source.ImageWMS({
    url:
      "https://geoserver.modaal.fr/geoserver/BDD_Référentiel/wms?&SERVICE=WMS&VERSION=1.3.0",
    params: {},
    attributions: ["OpenStreetMap"],
    serverType: "geoserver",
    crossOrigin: "Anonymous"
  })
});
export const oLayerParcellaire = new layer.Tile({
  key_layer: "parcelles_cadastrales",
  source: new source.WMTS({
    attributions: ["IGN-F/Géoportail"],
    url: "https://wxs.ign.fr/choisirgeoportail/geoportail/wmts",
    layer: "CADASTRALPARCELS.PARCELLAIRE_EXPRESS",
    crossOrigin: "Anonymous",
    matrixSet: "PM",
    format: "image/png",
    style: "PCI vecteur",
    projection: getProjection("EPSG:2154"),
    tileGrid: new WMTSTileGrid({
      origin: [-20037508, 20037508],
      resolutions: aResolutions,
      matrixIds: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19"
      ]
    })
  })
});
