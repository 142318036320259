<template>
  <v-card>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="cancelEditing()">
        {{ $t("buttons.close") }}
      </v-btn>
    </v-card-actions>
    <v-card-title class="justify-center">
      <span class="headline">{{ formTitleLayer }}</span>
    </v-card-title>
    <v-form v-model="validForm" ref="layer_form">
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-text-field
                  v-model="editedLayerGroup.name"
                  :counter="50"
                  :rules="[rules.required, rules.max(50)]"
                  :label="$t('fields.name') + ' *'"
                  validate-on-blur
                  @keyup.enter="$refs.edit_key_layer.focus()"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedLayerGroup.key_layer"
                  :counter="50"
                  :rules="[rules.required, rules.max(50)]"
                  :label="$t('fields.key_layer') + ' *'"
                  validate-on-blur
                  ref="edit_key_layer"
                  @keyup.enter="$refs.edit_z_min.focus()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="4">
                <v-subheader class="pl-0">
                  {{ $t("fields.opacity") + " *" }}
                  <v-row justify="end">
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedLayerGroup.opacity"
                        disabled
                        solo
                        class="mt-0 pt-0"
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-subheader>
                <v-slider
                  v-model="editedLayerGroup.opacity"
                  :thumb-size="24"
                  validate-on-blur
                  :rules="[rules.required]"
                  :step="0.1"
                  min="0.1"
                  max="1"
                >
                </v-slider>
              </v-col>
            </v-row>
            <v-row justify="center">
              <span>Zoom:</span>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-text-field
                  v-model.number="editedLayerGroup.min_zoom"
                  :label="$t('fields.min')"
                  :rules="[rules.number]"
                  type="number"
                  min="0"
                  max="20"
                  validate-on-blur
                  ref="edit_z_min"
                  @keyup.enter="$refs.edit_z_max.focus()"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="editedLayerGroup.max_zoom"
                  :label="$t('fields.max')"
                  type="number"
                  min="0"
                  max="20"
                  :rules="[rules.number]"
                  validate-on-blur
                  ref="edit_z_max"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <span>Thème:</span>
              <v-col cols="3">
                <v-select
                  v-model="editedLayerGroup.theme_id"
                  :items="srcTheme"
                  item-value="id"
                  item-text="name"
                  :label="$t('admin.selectThemeLayerGroup')"
                  :no-data-text="$t('admin.noDataTheme')"
                  single-line
                  fill
                ></v-select>
              </v-col>
              <v-col cols="1">
                <v-dialog v-model="cardAddTheme" width="450" persistent>
                  <template v-slot:activator="{ on: dialog, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          fab
                          x-small
                          color="primary"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...dialog }"
                        >
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("admin.tooltip_Theme") }}</span>
                    </v-tooltip>
                  </template>
                  <v-card
                    elevation="2"
                    max-width="344"
                    outlined
                    min-width="450"
                  >
                    <v-card-title>
                      {{ $t("admin.newTheme") }}
                      <v-spacer />
                      <v-btn
                        small
                        color="blue darken-1"
                        text
                        @click="cardAddTheme = !cardAddTheme"
                      >
                        {{ $t("buttons.close") }}
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="pb-0">
                      <v-data-table
                        dense
                        :items="srcTheme"
                        :headers="headersTheme"
                        :items-per-page="5"
                        :footer-props="{
                          itemsPerPageOptions: [5],
                          showFirstLastPage: true
                        }"
                      >
                        <template v-slot:top>
                          <v-toolbar flat dense color="white">
                            <span>{{ $t("admin.listTheme") }}</span>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small @click="deleteTheme(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                      <v-form v-model="validFormTheme" ref="theme_form">
                        <v-text-field
                          v-model="newThemeName"
                          :counter="255"
                          :rules="[rules.required, rules.max(255)]"
                          label="Nom du thème"
                          validate-on-blur
                        />
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="primary" dark small @click="addTheme()">
                        {{ $t("buttons.add") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="3">
                <v-checkbox
                  v-model="editedLayerGroup.visible"
                  :label="$t('fields.visible')"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="editedLayerGroup.searchable"
                  :label="$t('fields.searchable')"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="editedLayerGroup.searchable">
              <span>Attributs:</span>
            </v-row>
            <v-row justify="center" v-if="editedLayerGroup.searchable">
              <v-col cols="8">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Nom
                        </th>
                        <th class="text-left">
                          Libellé
                        </th>
                        <th class="text-left">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in Object.keys(editedLayerGroup.attributes)"
                        :key="item"
                      >
                        <td>
                          <v-text-field
                            @change="setNewAttributeKey(item)"
                            :value="item"
                            @input="attributeKey = $event"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model="editedLayerGroup.attributes[item].label"
                            :value="editedLayerGroup.attributes[item].label"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-icon right small @click="deleteAttribute(item)">
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-text-field
                            :label="$t('admin.newNameAttribute')"
                            v-model="attributeName"
                            @change="setNewAttribute()"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :label="$t('admin.newLabelAttribute')"
                            v-model="attributeLabel"
                            @change="setNewAttribute()"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <small>* {{ $t("fields.required") }}</small>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="saveLayer"
        :disabled="!validForm"
      >
        {{ $t("buttons.save") }}
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>
<script>
import GroupsServices from "../../../services/layer_group";
import ThemeServices from "../../../services/theme";

export default {
  data() {
    return {
      headersTheme: [
        {
          text: "Nom",
          align: "start",
          value: "name"
        },
        { text: "Actions", value: "actions", sortable: false }
      ],
      validFormTheme: null,
      newThemeName: null,
      srcTheme: [],
      cardAddTheme: false,
      attributeKey: null,
      attributeName: null,
      attributeLabel: null,
      layerGroupCreated: false,
      editedLayerGroup: {
        name: null,
        key_layer: null,
        max_zoom: null,
        min_zoom: null,
        opacity: null,
        visible: null,
        searchable: null,
        theme_id: null,
        attributes: {}
      },
      validForm: false,
      rules: {
        required: v => !!v || this.$t("fields.rules.required"),
        number: v =>
          Number.isInteger(v) || !v || this.$t("fields.rules.number"),
        max: x => v =>
          !v || (v && v.length <= x) || this.$t("fields.rules.max", { c: x })
      }
    };
  },
  props: {
    addOrUpdating: Number,
    layerGroupToEdit: Object
  },
  computed: {
    formTitleLayer() {
      return this.layerGroupToEdit.name
        ? "Modification du groupe de couche"
        : "Nouveau groupe de couche";
    }
  },
  watch: {
    $layerToEditGroup: {
      handler() {
        if (this.addOrUpdating == 2) {
          this.layerGroupCreated = true;
          this.editedLayerGroup = Object.assign({}, this.layerGroupToEdit);
          if (!this.editedLayerGroup.attributes)
            this.editedLayerGroup.attributes = {};
        }
        if (this.addOrUpdating == 1) {
          this.layerGroupCreated = false;
        }
        ThemeServices.getThemeList().then(res => (this.srcTheme = res.data));
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    deleteAttribute(item) {
      if (item) {
        confirm(
          "Êtes vous sûr de vouloir supprimer l'attribut " + item + " ?"
        ) && delete this.editedLayerGroup.attributes[item];
      }
      this.editedLayerGroup = Object.assign({}, this.editedLayerGroup);
    },
    cancelEditing() {
      this.editedLayerGroup = {};
      this.$emit("closeEditingPage");
    },
    handleKeydown(e) {
      if (e.key === "Escape") {
        this.cancelEditing();
      }
    },
    setNewAttributeKey(key) {
      let label = this.editedLayerGroup.attributes[key];
      if (label) {
        this.editedLayerGroup.attributes[this.attributeKey] = label;
        delete this.editedLayerGroup.attributes[key];
        this.$forceUpdate();
      }
    },
    setNewAttribute() {
      if (this.attributeName && this.attributeLabel) {
        if (this.editedLayerGroup.attributes) {
          this.editedLayerGroup.attributes[this.attributeName] = {
            label: this.attributeLabel
          };
          this.attributeName = null;
          this.attributeLabel = null;
        } else {
          this.editedLayerGroup.attributes = {};
          this.editedLayerGroup.attributes[this.attributeName] = {
            label: this.attributeLabel
          };
          this.attributeName = null;
          this.attributeLabel = null;
        }
        this.editedLayerGroup = Object.assign({}, this.editedLayerGroup);
      }
    },
    async saveLayer() {
      let formIsValid = this.$refs.layer_form.validate();
      if (!formIsValid) return;
      if (this.addOrUpdating == 1)
        try {
          await GroupsServices.postLayerGroup(this.editedLayerGroup);
          this.$toast.success("Groupe de couche créé");
          this.$emit("fillArray");
        } catch (e) {
          this.$toast.error(this.$t("toaster.error") + e);
        }
      if (this.addOrUpdating == 2)
        try {
          await GroupsServices.putLayerGroup(this.editedLayerGroup);
          this.$toast.success("Groupe de couche mis à jour");
          this.$emit("fillArray");
        } catch (e) {
          this.$toast.error(this.$t("toaster.error") + e);
        }
    },
    async addTheme() {
      let formIsValid = this.$refs.theme_form.validate();
      if (!formIsValid) return;
      try {
        let theme = await ThemeServices.postTheme({ name: this.newThemeName });
        this.newThemeName = null;
        this.srcTheme.push(theme.data);
      } catch (e) {
        this.$toast.error(this.$t("toaster.error") + e);
      }
    },
    async deleteTheme(item) {
      try {
        confirm(
          "Êtes vous sûr de vouloir supprimer le thème " + item.name + " ?"
        ) && (await ThemeServices.deleteTheme(item.id));
        let i = this.srcTheme.findIndex(a => a.id == item.id);
        this.srcTheme.splice(i, 1);
        this.$toast.success("Thème supprimé");
      } catch (e) {
        this.$toast.error(this.$t("toaster.error") + e);
      }
    }
  },
  created() {
    window.addEventListener("keyup", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeydown);
  }
};
</script>
